import React, { useState } from 'react';

import InsidePoolTable from '../InsidePoolTable/InsidePoolTable';
import Button from '../../../shared/Button/Button';
import './InsidePool.scss';
import Modal from '../../../shared/Modal/Modal';
import PrizesTable from '../PrizesTable/PrizesTable';
import Confirm from '../../../shared/Confirm/Confirm';
import { type Pool } from '../../../../interfaces/Pool';
import { type SearchPools } from '../../../../interfaces/SearchPools';
import { useAppDispatch } from '../../../../data/hooks';
import { fetchPools } from '../../../../data/slices/poolsSlice';
import { updatePool } from '../../../../data/slices/poolSlice';
import { ReactComponent as Plus } from '../../../../assets/icons/plus-green.svg';

interface Props {
  pool: Pool
  handleDataChange: (pool: Pool) => void
  requestData: SearchPools
}

const InsidePool = ({ pool, handleDataChange, requestData }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [prizeId, updatePrizeId] = useState<number | null>(null);
  const [poolPrizes, updatePoolPrizes] = useState(pool.prizes);
  const dispatch = useAppDispatch();

  const openAddModal = () => {
    setIsModalOpen(true);
  };

  const openDeleteAlert = (id: number) => {
    setIsAlertOpen(true);
    updatePrizeId(id);
  };

  const deletePrize = () => {
    const prizes = pool.prizes.filter((prize) => prize.id !== prizeId);
    handleDataChange({
      ...pool,
      prizes
    });
    setIsAlertOpen(false);
  };

  const cancel = () => {
    updatePoolPrizes(pool.prizes);
    setIsModalOpen(false);
  };

  const save = () => {
    updatePoolPrizes(poolPrizes);
    handleDataChange({
      ...pool,
      prizes: poolPrizes
    });
    setIsModalOpen(false);
  };

  const toggleEnabled = (active: boolean, id: number) => {
    const prizes = poolPrizes.map((prize) => {
      if (prize.id === id) {
        const pr = { ...prize };
        pr.active = active ? 0 : 1;
        return pr;
      }

      return prize;
    });

    updatePoolPrizes(prizes);
    handleDataChange({
      ...pool,
      prizes
    });
    const newPool = { ...pool };
    newPool.prizes = prizes;
    dispatch(updatePool(newPool)).then(() => {
      dispatch(fetchPools(requestData));
    });
  };

  return (
    <div className="inside-pool">
      <p className="info">
        New prize pool can have multiple prizes and can be defined as prize
        group for specific outcome in the game.
      </p>
      <div className="inside-pool__header">
        <Button type="secondary" onClick={openAddModal}>
          <Plus /> Add prize
        </Button>
      </div>
      <div className="inside-pool__body">
        <InsidePoolTable
          data={pool.prizes}
          openDeleteAlert={openDeleteAlert}
          toggleEnabled={toggleEnabled}
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="Add to pool"
        customClass="add-to-pool"
      >
        <div className="add-to-pool-table">
          <PrizesTable
              updatePoolPrizes={updatePoolPrizes}
              poolPrizes={poolPrizes}
          />
        </div>
        <div className="modal__footer">
          <div className="modal__footer--btns">
            <Button
              type="secondary"
              onClick={() => {
                cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                save();
              }}
            >
              Add prize
            </Button>
          </div>
        </div>
      </Modal>

      <Confirm
        isOpen={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        title="Remove prize"
        text="Are you sure you want to remove prize from this pool?"
        buttonText="Yes"
        type="danger"
        action={deletePrize}
      />
    </div>
  );
};

export default InsidePool;
