import { createSlice, type Dispatch } from '@reduxjs/toolkit';
import { getBatches } from '../api/batches';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING } from '../../constants/constants';

export interface BatchesState {
  data: any
  status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING
}

const initialState: BatchesState = {
  data: [],
  status: STATUS_IDLE
};

const batchesSlice = createSlice({
  name: 'batches',
  initialState,
  reducers: {
    getBatchesStarted: (state) => {
      state.status = STATUS_LOADING;
    },
    getBatchesSuccess: (state, action) => {
      state.status = STATUS_IDLE;
      if (action.payload.data) {
        state.data = action.payload.data;
      } else {
        state.data = initialState.data;
      }
    },
    getBatchesError: (state, action) => {
      state.status = STATUS_FAILED;
      console.log(action.payload.code, action.payload.message);
    },
    getEmptyBatch: (state, action) => {
      state.status = STATUS_IDLE;
      state.data = [];
    }
  }
});

const {
  getBatchesStarted,
  getBatchesSuccess,
  getBatchesError,
  getEmptyBatch
} = batchesSlice.actions;

export default batchesSlice.reducer;

export const fetchBatchesByPrizeId = (prizeId: number, codeType?: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getBatchesStarted());
      const result = await getBatches(prizeId, codeType);
      dispatch(getBatchesSuccess(result.data));
    } catch (error) {
      dispatch(getBatchesError(error));
    }
  };
};

export const emptyBatches = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmptyBatch([]));
  };
};
