import React, {
  type ChangeEventHandler, type FocusEventHandler,
  type MouseEventHandler, useEffect, useRef
} from 'react';

import './Input.scss';

interface Props {
  placeholder?: string
  name: string
  type: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: MouseEventHandler<HTMLInputElement>
  onFocusOut?: FocusEventHandler<HTMLInputElement>
  value?: string | number
  readOnly?: boolean
  min?: string | number
  max?: string | number
  dir?: string | undefined
  errors?: boolean
  disabled?: boolean
  autoFocus?: boolean
  customClasses?: string | undefined
}

const Input = ({
  placeholder,
  name,
  type,
  onChange,
  value,
  readOnly,
  min,
  max,
  dir,
  errors,
  disabled,
  onBlur,
  onFocusOut,
  customClasses,
  autoFocus
}: Props) => {
  const inputRef: any = useRef(null);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (autoFocus && inputRef.current && !value) {
        inputRef.current.focus();
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [autoFocus, value]);

  return (
    <input
      ref={inputRef}
      className={`input ${errors ? 'error' : ''}${customClasses ?? ''}`}
      placeholder={placeholder}
      name={name}
      type={type}
      onChange={onChange}
      onClick={onBlur}
      onBlur={onFocusOut}
      value={value}
      readOnly={!!readOnly}
      min={min}
      max={max}
      dir={dir ?? 'ltr'}
      aria-label="input"
      disabled={disabled}
    />
  );
};

export default Input;
