import React, { useState } from 'react';

import { ReactComponent as Info } from '../../../assets/icons/info.svg';
import { ReactComponent as SortDown } from '../../../assets/icons/sort-down.svg';
import { ReactComponent as SortUp } from '../../../assets/icons/sort-up.svg';
import ReactTooltip from 'react-tooltip';

interface Props {
  heading: string
  columnName?: string
  sorting?: boolean
  sortBy?: string
  order?: string
  setSort?: any
  infoText?: string | React.ReactNode
}

const TableHeadCell: React.FC<Props> = ({
  heading,
  sorting,
  order,
  columnName,
  sortBy,
  setSort,
  infoText
}) => {
  const [tooltip, showTooltip] = useState(true);
  return (
    <th>
      {heading}
      {sorting
        ? (order === 'ASC' && columnName === sortBy
            ? (
            <SortUp
              className={`sort-icon ${columnName === sortBy ? 'active' : ''}`}
              onClick={() =>
                setSort(columnName, 'DESC')
              }
            />
              )
            : (
          <SortDown
            className={`sort-icon ${columnName === sortBy ? 'active' : ''}`}
            onClick={() =>
              setSort(columnName, 'ASC')
            }
          />
              )
          )
        : (
            ''
          )}
      {infoText && (
        <>
          <Info
            className="info-icon"
            data-tip
            data-for="info"
            onMouseEnter={() => {
              showTooltip(true);
            }}
            onMouseLeave={() => {
              showTooltip(false);
              setTimeout(() => {
                showTooltip(true);
              }, 50);
            }}
          />
          {tooltip && (
            <ReactTooltip
              id="info"
              type="success"
              place="top"
              effect="solid"
              className="brame-tooltip"
            >
              {infoText}
            </ReactTooltip>
          )}
        </>
      )}
    </th>
  );
};

export default TableHeadCell;
