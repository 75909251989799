import { Listbox } from '@headlessui/react';
import React from 'react';

import './Dropdown.scss';
import { ReactComponent as AngleDown } from '../../../assets/icons/angle-down.svg';
import { ReactComponent as Check } from '../../../assets/icons/ic_check.svg';

interface Props {
  data: Array<{ id: string | number, label: string, disabled?: boolean, title?: string }>
  selectedValue: { id: string | number, label: string }
  handleChange: (value: { id: string | number, label: string }) => void
  width?: number | undefined
}

const Dropdown = ({ data, selectedValue, handleChange, width }: Props) => {
  return (
        <Listbox value={selectedValue} onChange={handleChange} as="div" className="dropdown">
            <Listbox.Button className="dropdown__btn" style={{ width: width ? width + 'px' : '144px' }}>
                {selectedValue.label}
                <AngleDown/>
            </Listbox.Button>
            <Listbox.Options className="dropdown__menu" style={{ width: width ? width + 'px' : '144px' }}>
                {data.map((item) => (
                    <Listbox.Option key={item.id} value={item} as={'div'} disabled={item.disabled}>
                        {({ active, selected }) => (
                            <li
                                className={`dropdown__menu--item ${
                                    (selected) ? 'selected' : (active) ? 'active' : (item.disabled) ? 'disabled' : ''

                                }`}
                                title={item.title ? item.title : ''}
                            >
                                {item.label} { selected ? <Check/> : ''}
                            </li>
                        )}
                    </Listbox.Option>
                ))}
            </Listbox.Options>
        </Listbox>
  );
};

export default Dropdown;
