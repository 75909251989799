import React, { useState } from 'react';
import './Switcher.scss';

interface Props {
  poolCount?: number | undefined
  prizeCount?: number | undefined
  setView: (type: 'prizes' | 'pools') => void
  selectedView: string
}

const Switcher = ({ poolCount, prizeCount, setView, selectedView }: Props) => {
  const [selectedTab, setSelectedTab] = useState(selectedView);

  const selectTab = (view: 'prizes' | 'pools') => {
    setSelectedTab(view);
    setView(view);
  };
  return (
    <div className="switcher">
      <span className={`prizes ${selectedTab === 'prizes' ? 'active' : 'inactive'}`}
            onClick={() => { selectTab('prizes'); }}>
        Prizes {prizeCount}
      </span>
      <span className={`pools ${selectedTab === 'pools' ? 'active' : 'inactive'}`}
            onClick={() => { selectTab('pools'); }}>
        Pools {poolCount}
      </span>
    </div>
  );
};

export default Switcher;
