import { type Prize } from "./Prize";
import { type WinProbability } from "./WinProbability";
import { type PrizeLimit } from "./PrizeLimit";

export interface Pool {
  campaignIds: string[];
  createdAt: string;
  description: string;
  durationInDays: number;
  id: number | null;
  internalPoolId: string;
  image: string;
  imageFilename: string | undefined;
  labels: string[];
  notes: string[];
  perPlay: number;
  prizeLimitConstant: number;
  prizeLimitType: number;
  prizeLimitPerDay: PrizeLimit[];
  prizes: Prize[];
  status: number;
  title: string;
  updatedAt: string;
  winProbabilityType: number;
  winProbabilityPerDay: WinProbability[];
  wins: number;
  amount: number;
  available: number;
  reserved: number;
  preAllocated: number;
  redeemed: number;
  companyId: string;
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class PoolFactory {
  static getInitialData(companyId: string | undefined): Pool {
    return {
      campaignIds: [],
      createdAt: "",
      description: "",
      durationInDays: 25,
      id: null,
      internalPoolId: "",
      image: "",
      imageFilename: "",
      labels: [],
      notes: [],
      perPlay: 100,
      prizeLimitConstant: 0,
      prizeLimitType: 0,
      prizeLimitPerDay: [],
      prizes: [],
      status: 2,
      title: "",
      updatedAt: "",
      winProbabilityType: 0,
      winProbabilityPerDay: [],
      wins: 0,
      amount: 0,
      available: 0,
      reserved: 0,
      preAllocated: 0,
      redeemed: 0,
      companyId: companyId ?? "",
    };
  }
}
