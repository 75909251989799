import { type CommonCodeBatch } from '../../interfaces/Batch';
import { api } from './api';

export const createBatch = async (data: FormData) => {
  return await api.post('prize-management/batch/create', data, { headers: { } })
    .then((result) => result);
};

export const putBatch = async (data: any, batchId: number) => {
  return await api.put(`prize-management/batch/${batchId}`, data)
    .then((result) => result);
};

export const getBatches = async (prizeId: number, type?: string) => {
  const params = {
    type
  };
  return await api.get(`prize-management/batches/${prizeId}`, { params })
    .then((result) => result);
};

export const deleteBatch = async (id: number) => {
  return await api.delete(`prize-management/batches/delete/${id}`)
    .then((result) => result);
};

export const saveCommonCodeBatch = async (prizeId: number, batch: CommonCodeBatch) => {
  if (!batch.id) {
    const data = new FormData();

    data.append('prizeId', `${prizeId}`);
    data.append('codesAmount', `${batch.codesAmount}`);
    data.append('commonCode', batch.commonCode);
    data.append('codeValidity', `${batch.codeValidity}`);
    data.append('expiration', `${batch.expiration}`);
    data.append('batchType', `${batch.batchType}`);
    data.append('codeType', `${batch.codeType}`);

    return await api.post('/prize-management/batch/create', data);
  }

  const { id, ...payload } = batch;

  return await api.put(`prize-management/batch/${batch.id}`, payload);
};
