import React from 'react';

interface Props {
  children: React.ReactNode
  transparent?: boolean
  sticky?: boolean
}
const TableHead: React.FC<Props> = ({ children, transparent, sticky }) => {
  return (
        <thead style={{
          background: transparent ? 'transparent' : '',
          position: sticky ? 'sticky' : 'relative',
          top: sticky ? '0' : ''
        }}>
        <tr>
            {children}
        </tr>
        </thead>
  );
};

export default TableHead;
