import { useEffect } from 'react';

interface RedirectType {
  authenticationPath: string
}

function Redirect ({ authenticationPath }: RedirectType) {
  useEffect(() => {
    window.location.replace(authenticationPath);
  }, [authenticationPath]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return <div />;
}

export default Redirect;
