import tokenService from "./TokenService";

class AuthService {
  logout() {
    tokenService.deleteCookieAccessToken();
    tokenService.deleteCookieRefreshToken();
    localStorage.removeItem("brame-context");
    window.location.replace(
      process.env.REACT_APP_AUTHENTICATION_URL ?? "https://brame.io",
    );
  }
}

const authService = new AuthService();
export default authService;
