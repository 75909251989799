import React from "react";
import { useAuth } from "../../../custom-hooks/useAuth";
import Redirect from "../Redirect/Redirect";

interface ProtectedRouteProps {
  authenticationPath?: string;
  // eslint-disable-next-line no-undef
  outlet: JSX.Element;
}

export default function ProtectedRoute({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  const { token } = useAuth();
  if (token) return outlet;
  return (
    <Redirect authenticationPath={authenticationPath ?? "https://brame.ch/"} />
  );
}
