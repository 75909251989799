import React, { useEffect, useState } from "react";

import Tabs from "../../../shared/Tabs/Tabs";
import PrizeDetails from "../PrizeDetails/PrizeDetails";
import Batches from "../Batches/Batches";
import Search from "../../../shared/Search/Search";
import Button from "../../../shared/Button/Button";
import PrizesTable from "../PrizesTable/PrizesTable";
import Modal from "../../../shared/Modal/Modal";
import { fetchPrizes } from "../../../../data/slices/prizesSlice";
import { useAppDispatch, useAppSelector } from "../../../../data/hooks";
import "./Prizes.scss";
import InPools from "../InPools/InPools";
import {
  addPrize,
  emptyPrize,
  removePrize,
  updatePrize,
} from "../../../../data/slices/prizeSlice";
import { type Prize, PrizeFactory } from "../../../../interfaces/Prize";
import {
  METHOD_ADD,
  METHOD_EDIT,
  STATUS_LOADING,
} from "../../../../constants/constants";
import Confirm from "../../../shared/Confirm/Confirm";
import ModalFooterData from "../ModalFooterData/ModalFooterData";
import FileUpload from "../../../shared/FileUpload/FileUpload";
import { addPrizes } from "../../../../data/slices/importPrizesSlice";
import { type CommonCodeBatch } from "../../../../interfaces/Batch";
import { emptyBatch } from "../../../../data/slices/batchSlice";
import { ReactComponent as Plus } from "../../../../assets/icons/plus.svg";
import { ReactComponent as Download } from "../../../../assets/icons/download.svg";
import { ReactComponent as Csv } from "../../../../assets/icons/csv.svg";
import Switcher from "../../../shared/Switcher/Switcher";
import { useAuth } from "../../../../custom-hooks/useAuth";
import { fetchPools } from "../../../../data/slices/poolsSlice";
import { checkPrizeSaveRules } from "../../../../util/util";
import { emptyBatches } from "../../../../data/slices/batchesSlice";

const errorsInitialState = {
  title: false,
  daysValid: false,
  barcode: false,
  amount: false,
  validity: false,
};

interface Props {
  setView: (type: "prizes" | "pools") => void;
}

export const AvailabilityContext = React.createContext<{
  availability: {
    amount: number;
    reserved: number;
    redeemed: number;
    available: number;
  };
  setAvailability: any;
}>({
  availability: {
    amount: 0,
    reserved: 0,
    redeemed: 0,
    available: 0,
  },
  setAvailability: null,
});

const Prizes = ({ setView }: Props) => {
  const {
    user: { companyId },
  } = useAuth();

  const initialPrizeData: Prize = PrizeFactory.getInitialData(companyId);

  const initialSearchPrizeData = {
    companyId: companyId ?? "",
    perPage: 10,
    pageNumber: 0,
    sortBy: "createdAt",
    order: "DESC",
    searchTerm: "",
  };
  const [isModalOpen, setIsOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [requestData, changeRequestData] = useState(initialSearchPrizeData);
  const [prize, changePrizeData] = useState(initialPrizeData);
  const [deletingPrize, setDeletingPrize] = useState(0);
  const [method, changeMethod] = useState(METHOD_ADD);
  const [errors, setErrors] = useState(errorsInitialState);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [file, updateFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState(false);
  const [commonCodeBatch, setCommonCodeBatch] =
    useState<CommonCodeBatch | null>(null);
  const [availability, setAvailability] = useState({
    amount: prize.amount,
    reserved: prize.reserved,
    redeemed: prize.redeemed,
    available: prize.available,
  });

  const prizes = useAppSelector((state: any) => state.prizes.data);
  const totalCount = useAppSelector((state: any) => state.prizes.dataCount);
  const poolsTotalCount = useAppSelector((state: any) => state.pools.dataCount);
  const status = useAppSelector((state: any) => state.prizes.status);
  const addedPrize = useAppSelector((state: any) => state.prize.data);
  const prizeStatus = useAppSelector((state: any) => state.prize.status);
  const importPrizesStatus = useAppSelector(
    (state: any) => state.importPrizes.status,
  );
  const prizeApiErrors = useAppSelector((state: any) => state.prize.errors);
  const dispatch = useAppDispatch();
  const batchStatus = useAppSelector((state: any) => state.batch.status);

  useEffect(() => {
    setAvailability({
      amount: prize.amount,
      reserved: prize.reserved,
      redeemed: prize.redeemed,
      available: prize.available,
    });
  }, [prize]);

  useEffect(() => {
    changeRequestData({
      ...requestData,
      companyId: companyId ?? "",
    });
  }, [companyId]);

  useEffect(() => {
    if (!isModalOpen) {
      dispatch(emptyPrize());
      dispatch(emptyBatch());
      dispatch(emptyBatches());
    }
  }, [isModalOpen, dispatch]);

  const isTabDisabled = () => {
    const params = ["title", "daysValid"];
    const invalid = [];
    Object.keys(prize).forEach((param: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (params.includes(param) && !prize[param]) {
        invalid.push(param);
      }
    });
    return invalid.length > 0;
  };

  const dataChange = (data: any) => {
    changePrizeData(data);
    setErrors(errorsInitialState);
  };

  const onCodeTypeChange = (codeType: string) => {
    dataChange({
      ...prize,
      codeType,
    });
  };

  const onCommonCodeBatchChange = (batch: CommonCodeBatch | null) => {
    setCommonCodeBatch(batch);
  };

  const save = () => {
    if (!validate()) {
      return;
    }
    if (method === METHOD_ADD) {
      dispatch(addPrize(prize)).then(() => {
        dispatch(fetchPrizes(requestData)).then(() => {
          changeMethod(METHOD_EDIT);
        });
      });
    } else {
      if (prize.id) {
        const payload = commonCodeBatch
          ? {
              ...prize,
              commonCodeBatch,
            }
          : prize;

        dispatch(updatePrize(payload, prize.id)).then(() => {
          if (!prizeApiErrors) {
            dispatch(fetchPrizes(requestData)).then(() => {
              toggle(method);
            });
          }
        });
      }
    }
  };

  const tabs = [
    {
      title: "Prize details",
      component: (
        <PrizeDetails
          handleOnChange={dataChange}
          data={prize}
          errors={errors}
          save={save}
        />
      ),
    },
    {
      title: "Codes & Limits",
      component: (
        <Batches
          data={prize}
          onCodeTypeChange={onCodeTypeChange}
          onCommonCodeBatchChangeCb={onCommonCodeBatchChange}
          errors={errors}
        />
      ),
      disabled: isTabDisabled(),
      tooltip: isTabDisabled() ? "Please save the prize first." : "",
    },
    {
      title: "In Pools",
      component: <InPools handleOnChange={changePrizeData} data={prize} />,
      disabled: isTabDisabled(),
      tooltip: isTabDisabled() ? "Please save the prize first." : "",
    },
  ];

  useEffect(() => {
    if (requestData.companyId) {
      dispatch(fetchPrizes(requestData));
      dispatch(fetchPools(initialSearchPrizeData));
    }
    if (addedPrize.id) {
      changePrizeData(addedPrize);
    }
  }, [requestData, addedPrize]);

  const toggle = (method: string) => {
    if (method === METHOD_ADD) {
      changePrizeData(initialPrizeData);
      setErrors(errorsInitialState);
      changeMethod(method);
      setIsOpen(!isModalOpen);
    } else {
      changeMethod(method);
      setIsOpen(!isModalOpen);
    }
  };

  const validate = () => {
    const errors = checkPrizeSaveRules(prize, commonCodeBatch);
    setErrors(errors);
    return Object.values(errors).every((value) => !value);
  };

  const onPageChange = (pageNumber: string | number) => {
    const page = pageNumber !== 0 ? +pageNumber - 1 : pageNumber;
    changeRequestData({
      ...requestData,
      pageNumber: page,
    });
  };

  const search = (searchTerm: string) => {
    changeRequestData({
      ...requestData,
      searchTerm,
      pageNumber: 0,
    });
  };

  const editPrize = (prizeId: number) => {
    changeMethod(METHOD_EDIT);
    setIsOpen(true);
    const prize = prizes.filter((prize: Prize) => prize.id === prizeId)[0];
    changePrizeData(prize);
  };

  const deleteAlert = (prizeId: number) => {
    setDeletingPrize(prizeId);
    setIsAlertOpen(true);
  };

  const deletePrize = () => {
    dispatch(removePrize(deletingPrize)).then(() => {
      setIsAlertOpen(false);
      const currentPrizes = prizes.filter(
        (prize: Prize) => prize.id !== deletingPrize,
      );
      if (currentPrizes.length === 0 && requestData.pageNumber > 0) {
        changeRequestData({
          ...requestData,
          pageNumber: requestData.pageNumber - 1,
        });
      } else {
        dispatch(fetchPrizes(requestData));
      }
    });
  };

  const handleSort = (sortBy: string, order: string) => {
    changeRequestData({
      ...requestData,
      sortBy,
      order,
    });
  };

  const onFileChange = (e: any) => {
    const files = e.target.files;
    if (files[0].type !== "text/csv") {
      return;
    }
    updateFile(files[0]);
  };

  const importPrizes = () => {
    if (!file) {
      setFileError(true);
      return;
    }
    const formData = new FormData();
    formData.append("companyId", initialSearchPrizeData.companyId);
    formData.append("filename", file);
    dispatch(addPrizes(formData)).then(() => {
      setIsUploadOpen(false);
      dispatch(fetchPrizes(requestData));
      updateFile(null);
      setFileError(false);
    });
  };

  const openFileUploadModal = () => {
    setFileError(false);
    setIsUploadOpen(true);
  };

  const downloadSample = () => {
    const url =
      "https://prize-management-data.s3.eu-central-1.amazonaws.com/sample-prizes-import.csv";
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div className="prizes">
      <div className="prizes__header">
        <div className="prizes__header--left">
          <p>Prizes</p>
          <Search search={search} />
        </div>

        <div className="prizes__header--center">
          <Switcher
            prizeCount={totalCount}
            poolCount={poolsTotalCount}
            setView={setView}
            selectedView="prizes"
          />
        </div>

        <div className="prizes__header--right">
          <Button
            type="secondary"
            customClass="mr-10"
            onClick={() => {
              openFileUploadModal();
            }}
          >
            Import prizes
          </Button>
          <Button
            type="primary"
            onClick={() => {
              toggle(METHOD_ADD);
            }}
          >
            <Plus /> Add new
          </Button>
        </div>
      </div>

      <PrizesTable
        data={prizes}
        isLoading={status === STATUS_LOADING}
        totalCount={totalCount}
        onPageChange={onPageChange}
        currentPage={requestData.pageNumber + 1}
        editPrize={editPrize}
        deletePrize={deleteAlert}
        handleSort={handleSort}
      />

      <AvailabilityContext.Provider
        value={{
          availability,
          setAvailability,
        }}
      >
        <Modal
          isOpen={isModalOpen}
          setIsOpen={setIsOpen}
          customClass="prizes-modal"
          title={method === METHOD_EDIT ? "Edit prize" : "Add new prize"}
        >
          <Tabs tabs={tabs} />

          <div className="modal__footer">
            {method === METHOD_EDIT ? (
              <ModalFooterData availability={availability} />
            ) : (
              ""
            )}
            <div className="modal__footer--btns">
              <Button
                type="secondary"
                customClass="btn-small"
                onClick={() => {
                  toggle(method);
                }}
                disabled={
                  prizeStatus === STATUS_LOADING ||
                  batchStatus === STATUS_LOADING
                }
              >
                Cancel
              </Button>
              <Button
                type="primary"
                customClass="btn-small"
                onClick={() => {
                  save();
                }}
                isLoading={
                  prizeStatus === STATUS_LOADING ||
                  batchStatus === STATUS_LOADING
                }
                disabled={
                  prizeStatus === STATUS_LOADING ||
                  batchStatus === STATUS_LOADING
                }
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
      </AvailabilityContext.Provider>

      <Modal
        isOpen={isUploadOpen}
        setIsOpen={setIsUploadOpen}
        title="Import prizes"
        customClass="import-prizes-modal"
      >
        <div className="import-prizes">
          <FileUpload
            filename={file ? file.name : ""}
            fileSize={file ? file.size : null}
            error={fileError}
            handleChange={onFileChange}
            clearFile={(e) => {
              e.preventDefault();
              updateFile(null);
            }}
          />
          <p>
            If you are not familiar with document formatting, you can download a
            sample below.
          </p>
          <Button
            type="secondary"
            customClass="sample-file"
            onClick={downloadSample}
          >
            <Csv />
            sample.csv
            <Download />
          </Button>
        </div>

        <div className="modal__footer">
          <div className="modal__footer--btns">
            <Button
              type="secondary"
              customClass="btn-small"
              onClick={() => {
                setIsUploadOpen(false);
                updateFile(null);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              customClass="btn-small"
              onClick={() => {
                importPrizes();
              }}
              isLoading={importPrizesStatus === STATUS_LOADING}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      <Confirm
        isOpen={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        title="Remove prize"
        text="Are you sure you want to remove this prize?"
        buttonText="Yes"
        type="danger"
        action={deletePrize}
      />
    </div>
  );
};

export default Prizes;
