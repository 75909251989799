import React, { useEffect, useState } from "react";

import "./PrizePools.scss";
import Search from "../../../shared/Search/Search";
import Button from "../../../shared/Button/Button";
import PrizePoolsTable from "../PrizePoolsTable/PrizePoolsTable";
import Modal from "../../../shared/Modal/Modal";
import Tabs from "../../../shared/Tabs/Tabs";
import PoolDetails from "../PoolDetails/PoolDetails";
import InsidePool from "../InsidePool/InsidePool";
import InCampaign from "../InCampaign/InCampaign";
import LimitsAndProbability from "../LimitsAndProbability/LimitsAndProbability";
import { useAppDispatch, useAppSelector } from "../../../../data/hooks";
import {
  fetchPools,
  deletePool as deletePoolAction,
} from "../../../../data/slices/poolsSlice";
import { copyPool as copyPoolAction } from "../../../../data/slices/copySlice";
import { type SearchPools } from "../../../../interfaces/SearchPools";
import { addPool, updatePool } from "../../../../data/slices/poolSlice";
import { type Pool, PoolFactory } from "../../../../interfaces/Pool";
import {
  METHOD_EDIT,
  METHOD_ADD,
  STATUS_LOADING,
} from "../../../../constants/constants";
import { emptyCampaign } from "../../../../data/slices/campaignSlice";
import Confirm from "../../../shared/Confirm/Confirm";
import { type RootState } from "../../../../data/store";
import Switch from "../../../shared/Switch/Switch";
import Switcher from "../../../shared/Switcher/Switcher";
import { useAuth } from "../../../../custom-hooks/useAuth";
import { Link } from "react-router-dom";
import { ReactComponent as Plus } from "../../../../assets/icons/plus.svg";
import { modal } from "../../../../data/slices/notificationSlice";
import { fetchPrizes } from "../../../../data/slices/prizesSlice";
import { checkPrizePoolSaveRules } from "../../../../util/util";

const errorsInitialState = {
  title: false,
  durationInDays: false,
  winsPerPlay: false,
  numberPerPlay: false,
};

const copyPoolInitialState = {
  poolId: 0,
  copyCodes: false,
};

interface Props {
  setView: (type: "prizes" | "pools") => void;
}

export const AllocationContext = React.createContext<{
  totalAllocation: number;
  setTotalAllocation: any;
}>({
  totalAllocation: 0,
  setTotalAllocation: null,
});

const PrizePools = ({ setView }: Props) => {
  const {
    user: { companyId },
  } = useAuth();

  const initialPoolData: Pool = PoolFactory.getInitialData(companyId);

  const initialRequestData: SearchPools = {
    companyId: companyId ?? "",
    perPage: 10,
    pageNumber: 0,
    sortBy: "createdAt",
    order: "DESC",
    searchTerm: "",
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [copyPoolPayload, setCopyPoolPayload] = useState<{
    poolId: number;
    copyCodes?: boolean;
  }>(copyPoolInitialState);
  const [requestData, changeRequestData] = useState(initialRequestData);
  const [pool, changePoolData] = useState(initialPoolData);
  const [method, changeMethod] = useState(METHOD_ADD);
  const [errors, setErrors] = useState(errorsInitialState);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [deletingPoolId, setDeletingPoolId] = useState(0);
  const pools = useAppSelector((state: any) => state.pools.data);
  const totalCount = useAppSelector((state: any) => state.pools.dataCount);
  const prizesTotalCount = useAppSelector(
    (state: any) => state.prizes.dataCount,
  );
  const status = useAppSelector((state: any) => state.pools.status);
  const addedPool = useAppSelector((state: any) => state.pool.data);
  const poolStatus = useAppSelector((state: any) => state.pool.status);
  const campaign = useAppSelector((state: any) => state.campaign.data);
  const isCopyInProgress = useAppSelector(
    (state: RootState) => state.copy.pool.status === STATUS_LOADING,
  );
  const [totalAllocation, setTotalAllocation] = useState<number>(0);
  const dispatch = useAppDispatch();

  const isTabDisabled = () => {
    const params = ["title", "durationInDays"];
    const invalid = [];
    Object.keys(pool).forEach((param: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (params.includes(param) && !pool[param]) {
        invalid.push(param);
      }
    });

    return invalid.length > 0;
  };

  const dataChange = (data: any) => {
    changePoolData(data);
    setErrors(errorsInitialState);
  };

  const handleSort = (sortBy: string, order: "ASC" | "DESC") => {
    changeRequestData({
      ...requestData,
      sortBy,
      order,
    });
  };

  const save = () => {
    if (!validate()) {
      return;
    }
    if (method === METHOD_ADD) {
      dispatch(addPool(pool)).then(() => {
        dispatch(fetchPools(requestData));
        changeMethod(METHOD_EDIT);
      });
    } else {
      dispatch(updatePool(pool)).then(() => {
        dispatch(fetchPools(requestData));
      });
      toggle(METHOD_EDIT);
    }
  };

  const tabs = [
    {
      title: "Pool details",
      component: (
        <PoolDetails
          handleDataChange={dataChange}
          pool={pool}
          errors={errors}
          save={save}
        />
      ),
    },
    {
      title: "Prize inside pool",
      component: (
        <InsidePool
          handleDataChange={changePoolData}
          pool={pool}
          requestData={requestData}
        />
      ),
      disabled: isTabDisabled(),
      tooltip: isTabDisabled() ? "Please populate the details first." : "",
    },
    {
      title: "In campaign",
      component: <InCampaign handleDataChange={changePoolData} pool={pool} />,
      disabled: isTabDisabled(),
      tooltip: isTabDisabled() ? "Please populate the details first." : "",
    },
    {
      title: "Limits & probability",
      component: (
        <LimitsAndProbability
          handleDataChange={changePoolData}
          pool={pool}
          winsPerPlayError={errors.winsPerPlay}
          numberPerPlayError={errors.numberPerPlay}
        />
      ),
      disabled: isTabDisabled(),
      tooltip: isTabDisabled() ? "Please populate the details first." : "",
    },
  ];

  useEffect(() => {
    if (addedPool.id) {
      changePoolData(addedPool);
    }
    if (!requestData) return;
    dispatch(fetchPools(requestData));
    dispatch(fetchPrizes(initialRequestData));
  }, [addedPool, dispatch, requestData]);

  const toggle = (method: string) => {
    if (method === METHOD_ADD) {
      changePoolData(initialPoolData);
      setErrors(errorsInitialState);
      changeMethod(method);
      setIsOpen(!isOpen);
    } else {
      dispatch(emptyCampaign());
      changeMethod(method);
      setIsOpen(!isOpen);
    }
  };

  const validate = () => {
    const errors = checkPrizePoolSaveRules(pool);
    setErrors(errors);

    if (errors.winsPerPlay) {
      dispatch(
        modal.notify({
          message: "Please fill out all required fields (Wins per play).",
          type: "error",
        }),
      );
    }

    if (errors.numberPerPlay) {
      dispatch(
        modal.notify({
          message: "Please fill out all required fields (plays)",
          type: "error",
        }),
      );
    }

    return Object.values(errors).every((value) => !value);
  };

  const onPageChange = (pageNumber: string | number) => {
    const page = pageNumber !== 0 ? +pageNumber - 1 : pageNumber;
    changeRequestData({
      ...requestData,
      pageNumber: page,
    });
  };

  const search = (searchTerm: string) => {
    changeRequestData({
      ...requestData,
      searchTerm,
      pageNumber: 0,
    });
  };

  const edit = (poolId: number) => {
    changeMethod(METHOD_EDIT);
    setIsOpen(true);
    const pool = pools.filter((pool: Pool) => pool.id === poolId)[0];
    changePoolData(pool);
  };

  const showDeleteAlter = (poolId: number) => {
    setDeletingPoolId(poolId);
    setIsDeleteAlertOpen(true);
  };

  const deletePool = () => {
    if (!deletingPoolId) {
      return;
    }

    dispatch(deletePoolAction(deletingPoolId)).then(() => {
      setIsDeleteAlertOpen(false);

      const poolsLength = pools.length - 1;

      if (poolsLength < 1 && requestData.pageNumber > 0) {
        changeRequestData({
          ...requestData,
          pageNumber: requestData.pageNumber - 1,
        });
      } else {
        dispatch(fetchPools(requestData));
      }
    });
  };

  const showCopyModal = (poolId: number) => {
    setCopyPoolPayload((payload) => ({
      ...payload,
      poolId,
    }));
    setIsCopyModalOpen(true);
  };

  const onCopySuccess = () => {
    setIsCopyModalOpen(false);
    dispatch(fetchPools(requestData));
  };

  const copyPool = () => {
    const { poolId, ...payload } = copyPoolPayload;

    dispatch(copyPoolAction(poolId, payload)).then(onCopySuccess);
  };

  const getCampaignId = () => {
    if (campaign) {
      return campaign.id;
    }
    return null;
  };

  return (
    <div className="pools">
      <div className="pools__header">
        <div className="pools__header--left">
          <p>Prize Pools</p>
          <Search search={search} />
        </div>

        <div className="pools__header--center">
          <Switcher
            poolCount={totalCount}
            prizeCount={prizesTotalCount}
            setView={setView}
            selectedView="pools"
          />
        </div>

        <div className="pools__header--right">
          <Button
            type="primary"
            onClick={() => {
              toggle(METHOD_ADD);
            }}
          >
            <Plus /> Add new
          </Button>
        </div>
      </div>

      <PrizePoolsTable
        data={pools}
        isLoading={status === STATUS_LOADING}
        totalCount={totalCount}
        onPageChange={onPageChange}
        currentPage={requestData.pageNumber + 1}
        edit={edit}
        onDelete={showDeleteAlter}
        onCopyClick={showCopyModal}
        handleSort={handleSort}
      />

      <AllocationContext.Provider
        value={{
          totalAllocation,
          setTotalAllocation,
        }}
      >
        <Modal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={method === METHOD_EDIT ? "Edit pool" : "Add new pool"}
          customClass="pools-modal"
        >
          <Tabs
            tabs={tabs}
            type="small"
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />

          <div className="modal__footer">
            {selectedIndex === 2 ? (
              <>
                {getCampaignId() ? (
                  <Link
                    to={`/search-winner/${getCampaignId()}`}
                    target="_blank"
                    className="link-small"
                  >
                    Search for the winner
                  </Link>
                ) : null}
              </>
            ) : (
              ""
            )}
            {selectedIndex === 3 ? (
              <p className="allocation">{`Total prize allocation: ${totalAllocation}`}</p>
            ) : (
              ""
            )}
            <div className="modal__footer--btns">
              <Button
                type="secondary"
                customClass="btn-small"
                onClick={() => {
                  toggle(method);
                  setSelectedIndex(0);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                customClass="btn-small"
                onClick={() => {
                  save();
                  setSelectedIndex(0);
                }}
                disabled={poolStatus === STATUS_LOADING}
                isLoading={poolStatus === STATUS_LOADING}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
      </AllocationContext.Provider>

      <Modal
        isOpen={isCopyModalOpen}
        setIsOpen={setIsCopyModalOpen}
        title={`Copy pool ${pools.find(
          (p: Pool) => p.id === copyPoolPayload.poolId,
        )?.title}`}
        customClass="copy-pool"
      >
        <div className="generate-codes">
          <Switch
            active={!!copyPoolPayload.copyCodes}
            handleChange={() => {
              setCopyPoolPayload((payload) => ({
                ...payload,
                copyCodes: !payload.copyCodes,
              }));
            }}
          />
          <p>Generate codes</p>
        </div>
        <div className="modal__footer">
          <div className="modal__footer--btns">
            <Button
              type="secondary"
              customClass="btn-small"
              onClick={() => {
                setIsCopyModalOpen(false);
              }}
              disabled={isCopyInProgress}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              customClass="btn-small"
              onClick={() => {
                copyPool();
              }}
              disabled={isCopyInProgress}
              isLoading={isCopyInProgress}
            >
              Copy
            </Button>
          </div>
        </div>
      </Modal>

      <Confirm
        isOpen={isDeleteAlertOpen}
        setIsOpen={setIsDeleteAlertOpen}
        title="Delete pool"
        text="Are you sure you want to delete this pool?"
        buttonText="Yes"
        type="danger"
        action={deletePool}
      />
    </div>
  );
};

export default PrizePools;
