import { apiOld } from "./api";
import { type SearchCampaigns } from "../../interfaces/SearchCampaigns";
import { type Campaign, CampaignType } from "../../interfaces/Campaign";

const allowedCampaignTypes = [
  CampaignType.ADVANCED_SPIN_THE_WHEEL,
  CampaignType.BIPA_SPIN_THE_WHEEL,
  CampaignType.PENNY_SPIN_THE_WHEEL,
  CampaignType.PENNY_SLOT_MACHINE,
  CampaignType.PENNY_PATH,
];

export const getCampaigns = async (data: SearchCampaigns) => {
  const params = {
    page: data.page,
    size: data.size,
    companyId: data.companyId,
    orderBy: data.orderBy,
    direction: data.direction,
    name: data.name,
  };
  return await apiOld.get("campaign/search", { params }).then((result) => {
    return result.data.data.content.filter(
      (item: Campaign) =>
        item.builderVersion === "1.0" &&
        allowedCampaignTypes.includes(item.campaignType),
    );
  });
};

export const getCampaign = async (campaignId: string) => {
  return await apiOld.get(`campaign/${campaignId}`).then((result) => result);
};
