import React from "react";
import "./Loader.scss";

interface Props {
  // eslint-disable-next-line no-undef
  children: JSX.Element;
  isLoading: boolean;
  height?: number;
}

const Loader = ({isLoading, height, children}: Props) => {
  if (isLoading) {
    return (
      <div className="loader-wrapper" style={{ height: height + 'px' }}>
        <svg className="spinner" viewBox="0 0 50 50">
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
    );
  }

  return children;
};

export default Loader;
