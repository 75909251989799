import {Listbox} from '@headlessui/react';
import React from 'react';

import './ActionsDropdown.scss';
import {ReactComponent as ThreeDots} from '../../../assets/icons/three-dots.svg';
import {ReactComponent as Check} from '../../../assets/icons/ic_check.svg';

interface Props {
  data: Array<{ id: string | number, label: string, disabled?: boolean, title?: string }>
  handleChange: (value: { id: string | number, label: string }) => void
  width?: number | undefined
}

const ActionsDropdown = ({data, handleChange}: Props) => {
  return (
    <Listbox value={null} onChange={handleChange} as="div" className="actions-dropdown">
      <Listbox.Button className="actions-dropdown__btn">
        <ThreeDots/>
      </Listbox.Button>
      <Listbox.Options className="actions-dropdown__menu" style={{width: '160px'}}>
        {data.map((item) => (
          <Listbox.Option key={item.id} value={item} as={'div'} disabled={item.disabled}>
            {({active, selected}) => (
              <li
                className={`actions-dropdown__menu--item ${
                  (selected) ? 'selected' : (active) ? 'active' : (item.disabled) ? 'disabled' : ''
                }`}
                title={item.title ? item.title : ''}
              >
                {item.label} {selected ? <Check/> : ''}
              </li>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};

export default ActionsDropdown;
