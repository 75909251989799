import { createSlice } from '@reduxjs/toolkit';

export interface NotificationState {
  notifications: Array<{ message: string, type: string }>
}

const initialState: NotificationState = {
  notifications: []
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    notify: (state, action) => {
      state.notifications.push({
        message: action.payload.message,
        type: action.payload.type
      });
    },
    emptyNotifications: (state) => {
      state.notifications = [];
    }
  }
});

export const modal = notificationSlice.actions;

export default notificationSlice.reducer;
