import { api } from './api';
import { type Win } from '../../interfaces/Win';

interface RequestBody {
  searchTerm: string
  campaignId: string
  perPage: number
  pageNumber: number
  sortBy: string
  order: 'ASC' | 'DESC'
}

export const searchWinners = async (data: RequestBody) => {
  return await api.post('/prize-management/winners/search', data)
    .then((result) => result);
};

export const resendPrizeCode = async (data: { uuid: string, campaignId: string }) => {
  return await api.post('/prize-management/winners/resend-code', data)
    .then((result) => result);
};

export const redeemPrizeCode = async (data: { playId: string, campaignId: string }) => {
  return await api.post('/prize-management/winners/redeem-prize', data)
    .then((result) => result);
};

export const getPrizes = async (campaignId: string) => {
  return await api.get(`/prize-management/winners/${campaignId}/prizes`)
    .then((result) => result);
};

export const generateWin = async (data: Win) => {
  return await api.post('/prize-management/winners/generate-win', data)
    .then((result) => result);
};
