import { api } from './api';
import { type Prize } from '../../interfaces/Prize';

export const searchPrizes = async (data: any) => {
  return await api.post('prize-management/prizes', data)
    .then((result) => result);
};

export const importPrizes = async (data: FormData) => {
  return await api.post('prize-management/prizes/import', data)
    .then((result) => result);
};

export const getPrize = async (id: number) => {
  return await api.get(`prize-management/prizes/${id}`)
    .then((result) => result);
};

export const createPrize = async (data: any) => {
  return await api.post('prize-management/prizes/create', data)
    .then((result) => result);
};

export const putPrize = async (data: Prize, id: number) => {
  return await api.put(`prize-management/prize/${id}`, data)
    .then((result) => result);
};

export const deletePrize = async (id: number) => {
  return await api.delete(`prize-management/prizes/delete/${id}`)
    .then((result) => result);
};
