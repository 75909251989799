import React from "react";

import Input from "../../../shared/Input/Input";
import Dropdown from "../../../shared/Dropdown/Dropdown";
import "./LimitsAndProbability.scss";
import Timeline from "../../../shared/Timeline/Timeline";
import { type Pool } from "../../../../interfaces/Pool";
import {
  LIMIT_DIFFERENT,
  LIMIT_SAME,
  LIMIT_UNLIMITED,
  PROBABILITY_DIFFERENT,
  PROBABILITY_SAME,
} from "../../../../constants/constants";
import { type PrizeLimit } from "../../../../interfaces/PrizeLimit";
import { modal } from "../../../../data/slices/notificationSlice";
import { useAppDispatch } from "../../../../data/hooks";

const limitOptions = [
  {
    id: LIMIT_SAME,
    label: "Same limit for all days",
  },
  {
    id: LIMIT_DIFFERENT,
    label: "Different limit for each day",
  },
  {
    id: LIMIT_UNLIMITED,
    label: "Unlimited",
  },
];

const probabilityOptions = [
  {
    id: PROBABILITY_SAME,
    label: "Same probability for all days",
  },
  {
    id: PROBABILITY_DIFFERENT,
    label: "Different probability for each day",
  },
];

interface Props {
  pool: Pool;
  handleDataChange: (data: any) => void;
  winsPerPlayError: boolean;
  numberPerPlayError: boolean;
}

const LimitsAndProbability = ({
  pool,
  handleDataChange,
  winsPerPlayError,
  numberPerPlayError,
}: Props) => {
  const changeLimitType = (option: { id: number | string; label: string }) => {
    let prizeLimitPerDay: PrizeLimit[] = [];
    if (option.id === PROBABILITY_DIFFERENT) {
      prizeLimitPerDay = populatePrizeLimitPerDay();
    }
    handleDataChange({
      ...pool,
      prizeLimitType: option.id,
      prizeLimitPerDay,
      prizeLimitConstant: pool.prizeLimitConstant ?? 0,
    });
  };
  const dispatch = useAppDispatch();

  const changeProbabilityType = (option: {
    id: number | string;
    label: string;
  }) => {
    handleDataChange({
      ...pool,
      winProbabilityType: option.id,
    });
  };

  const populatePrizeLimitPerDay = () => {
    const prizeLimitPerDay: PrizeLimit[] = [];

    for (let i = 0; i < pool.durationInDays; i++) {
      prizeLimitPerDay.push({
        onDate: i + 1,
        prizeLimit: pool.prizeLimitConstant,
        id: Math.random(),
      });
    }
    return prizeLimitPerDay;
  };

  const handleInputChange = (event: any) => {
    let value = Math.abs(Number(event.target.value));
    const name = event.target.name;
    const isDurationInDays = name === "durationInDays";

    if (isDurationInDays) {
      value = Math.min(value, 92);
    }

    const winProbabilityPerDay = isDurationInDays
      ? truncateDuration(pool.winProbabilityPerDay, value)
      : pool.winProbabilityPerDay;
    const prizeLimitPerDay = isDurationInDays
      ? truncateDuration(pool.prizeLimitPerDay, value)
      : pool.prizeLimitPerDay;

    handleDataChange({
      ...pool,
      winProbabilityPerDay,
      prizeLimitPerDay,
      [name]: value,
    });
  };

  const truncateDuration = (data: any, value: number) => {
    return data.filter((probability: any) => probability.onDate <= value);
  };

  const getLimitType = () => {
    return (
      limitOptions.find((option) => option.id === pool.prizeLimitType) ??
      limitOptions[0]
    );
  };

  const getProbabilityType = () => {
    return (
      probabilityOptions.find(
        (option) => option.id === pool.winProbabilityType,
      ) ?? probabilityOptions[0]
    );
  };

  if (winsPerPlayError) {
    dispatch(
      modal.notify({
        message:
          "Wins per play can't be higher than 100 percent and cannot be empty.",
        type: "error",
      }),
    );
  }

  if (numberPerPlayError) {
    dispatch(
      modal.notify({
        message: "Number per play can't be 0.",
        type: "error",
      }),
    );
  }

  return (
    <>
      <p className="info">
        New prize pool can have multiple prizes and can be defined as prize
        group for specific outcome in the game.
      </p>
      <div className="limits">
        <div className="limits__top">
          <div className="limits__type">
            <Dropdown
              data={limitOptions}
              selectedValue={getLimitType()}
              handleChange={changeLimitType}
              width={280}
            />
            {getLimitType().id === LIMIT_SAME ? (
              <span className="label-inside label-inside__prizes">
                <Input
                  name="prizeLimitConstant"
                  type="number"
                  onChange={handleInputChange}
                  value={pool.prizeLimitConstant ? pool.prizeLimitConstant : 0}
                  min="0"
                />
                <span>prizes</span>
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="limits__duration">
            <span>Duration</span>
            <span className="label-inside label-inside__days">
              <Input
                placeholder="0"
                name="durationInDays"
                type="number"
                onChange={handleInputChange}
                value={pool.durationInDays ? pool.durationInDays : 0}
                min="0"
              />
            </span>
          </div>
        </div>
        <div className="limits__bottom">
          <div className="limits__probability">
            <Dropdown
              data={probabilityOptions}
              selectedValue={getProbabilityType()}
              handleChange={changeProbabilityType}
              width={280}
            />
            {getProbabilityType().id === PROBABILITY_SAME && (
              <span className="label-inside label-inside__wins">
                <Input
                  placeholder="10"
                  type="number"
                  name="wins"
                  onChange={handleInputChange}
                  value={pool.wins ? pool.wins : ""}
                  min="1"
                />
                <span>wins</span>
              </span>
            )}
          </div>
          {getProbabilityType().id === PROBABILITY_SAME && (
            <div className="limits__perPlay">
              <span>Based on</span>
              <span className="label-inside label-inside__plays">
                <Input
                  placeholder="100"
                  type="number"
                  name="perPlay"
                  onChange={handleInputChange}
                  value={pool.perPlay ? pool.perPlay : ""}
                  min="1"
                />
              </span>
            </div>
          )}
        </div>
        <div className="limits__timeline">
          <Timeline pool={pool} handleDataChange={handleDataChange} />
        </div>
      </div>
    </>
  );
};

export default LimitsAndProbability;
