import { Tab } from '@headlessui/react';
import React, { Fragment } from 'react';

import './Tabs.scss';

interface Props {
  tabs: Array<{
    title: string
    // eslint-disable-next-line no-undef
    component: JSX.Element
    disabled?: boolean
    tooltip?: string | undefined
  }>
  type?: 'big' | 'small'
  selectedIndex?: number
  setSelectedIndex?: (index: number) => void
}

const disabledTooltip =
  'Save your initial settings before you can proceed to other tabs.';

const Tabs = ({ tabs, type, selectedIndex, setSelectedIndex }: Props) => {
  return (
    <Tab.Group
      as="div"
      className={`tabs ${type}`}
      selectedIndex={selectedIndex ?? 0}
    >
      <Tab.List as="div" className="tabs__list">
        {tabs.map((tab, index) => (
          <Tab key={index} as={Fragment}>
            {({ selected }) => (
              <button
                className={`tabs__btn ${selected ? 'active' : ''}`}
                disabled={tab.disabled}
                title={tab.disabled ? disabledTooltip : tab.tooltip}
                onClick={() =>
                  setSelectedIndex ? setSelectedIndex(index) : ''
                }
              >
                {tab.title}
              </button>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        {tabs.map((tab, index) => (
          <Tab.Panel key={index}>{tab.component}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default Tabs;
