import React from 'react';
import './EmptyBox.scss';
import { ReactComponent as NoItems } from '../../../assets/icons/no-items.svg';

interface Props {
  height?: number
  title: string
  description?: string | undefined
}

const EmptyBox = ({ height, title, description }: Props) => {
  return (
        <div className="empty-box" style={{ height: height + 'px' }}>
            <NoItems/>
            <p className="title">{title}</p>
            {description
              ? (
                <p className="description">{description}</p>
                )
              : ('')}
        </div>
  );
};

export default EmptyBox;
