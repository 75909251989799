import React, { type ChangeEventHandler } from 'react';

import './TextArea.scss';

interface Props {
  placeholder: string
  name: string
  onChange: ChangeEventHandler<HTMLTextAreaElement>
  value?: string | number
  customClass?: string | undefined
}

const TextArea = ({ placeholder, name, onChange, value, customClass }: Props) => {
  return (
        <textarea className={`textarea ${customClass}`} placeholder={placeholder} name={name} onChange={onChange}
                  value={value}/>
  );
};

export default TextArea;
