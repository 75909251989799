import React, { useState } from 'react';

import './InPools.scss';
import Button from '../../../shared/Button/Button';
import InPoolsTable from '../InPoolsTable/InPoolsTable';
import { type Prize } from '../../../../interfaces/Prize';
import { type Pool } from '../../../../interfaces/Pool';
import { ReactComponent as Plus } from '../../../../assets/icons/plus-green.svg';

interface Props {
  data: Prize
  handleOnChange: (pools: Prize) => void
}

const InPools: React.FC<Props> = ({ data, handleOnChange }: Props) => {
  const [isOpenAddToPoolModal, setIsAddToPoolModal] = useState(false);

  const handlePoolChange = (pool: Pool | null) => {
    if (pool) {
      handleOnChange({
        ...data,
        pools: [pool]
      });
      setIsAddToPoolModal(false);
    } else {
      handleOnChange({
        ...data,
        pools: []
      });
    }
  };

  return (
    <div className="prize-pools">
      <p>
        Prize pool can have multiple prizes and can be defined as prize group
        for specific outcome in the game.
      </p>
      <div className="prize-pools__header">
        <Button
          type="secondary"
          onClick={() => {
            setIsAddToPoolModal(!isOpenAddToPoolModal);
          }}
          disabled={!data.id || data.pools.length > 0}
        >
          <Plus /> Add to pool
        </Button>
      </div>

      <div className="prize-pools__body">
        <InPoolsTable
          data={data.pools}
          isOpenAddToPoolModal={isOpenAddToPoolModal}
          setIsAddToPoolModal={setIsAddToPoolModal}
          handleOnChange={handlePoolChange}
        />
      </div>
    </div>
  );
};

export default InPools;
