import React, { useState } from "react";

import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Loader from "../../../shared/Loader/Loader";
import Table from "../../../shared/Table/Table";
import TableBody from "../../../shared/Table/TableBody";
import TableHead from "../../../shared/Table/TableHead";
import TableHeadCell from "../../../shared/Table/TableHeadCell";
import TableRow from "../../../shared/Table/TableRow";
import TableCell from "../../../shared/Table/TableCell";
import Pagination from "../../../shared/Pagination/Pagination";
import { type Column } from "../../../../interfaces/Column";
import { ReactComponent as Edit } from "../../../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../../../assets/icons/delete.svg";
import { ReactComponent as Copy } from "../../../../assets/icons/copy.svg";
import {
  POOL_ACTIVE,
  POOL_DEFAULT,
  POOL_DRAFT,
  POOL_INACTIVE,
  POOL_WALLET,
} from "../../../../constants/constants";
import EmptyBox from "../../../shared/EmptyBox/EmptyBox";
import { formatDateTime, getSortBy } from "../../../../util/util";

interface Props {
  data: any[];
  totalCount: number;
  isLoading: boolean;
  currentPage: number;
  onPageChange: (pageNumber: string | number) => void;
  edit: (id: number) => void;
  onDelete: (id: number) => void;
  onCopyClick: (id: number) => void;
  handleSort: (sortBy: string, order: "ASC" | "DESC") => void;
}

const PrizePoolsTable: React.FC<Props> = (props: Props) => {
  const {
    data,
    totalCount,
    isLoading,
    onPageChange,
    currentPage,
    edit,
    onDelete,
    onCopyClick,
    handleSort,
  } = props;
  const [sort, setSort] = useState({
    by: "createdAt",
    order: "DESC",
  });

  const changeSorting = (by: string, order: "ASC" | "DESC") => {
    setSort({
      by,
      order,
    });
    handleSort(getSortBy(by), order);
  };

  const columns: Column[] = [
    {
      label: "Status",
      value: "status",
      render: (data: any, columnValue: string) => (
        <div className={`activity ${checkStatus(data[columnValue])}`}>
          {checkStatus(data[columnValue])}
        </div>
      ),
    },
    {
      label: "ID",
      value: "internalPoolId",
      sorting: true,
    },
    {
      label: "Image",
      value: "image",
      render: (data: any, columnValue: string) =>
        data[columnValue] ? (
          <img src={data[columnValue]} alt="Pool" />
        ) : (
          <img
            src={require("./../../../../assets/images/white.png")}
            alt="Pool"
          />
        ),
    },
    {
      label: "Title",
      value: "title",
      render: (data: any) => (
        <span className="cursor-pointer" onClick={() => edit(data.id)}>
          {data.title}
        </span>
      ),
      sorting: true,
    },
    {
      label: "Description",
      value: "description",
      sorting: true,
    },
    {
      label: "Prizes",
      value: "prizes",
      render: (data, columnValue: string) => data[columnValue].length,
    },
    {
      label: "Amount",
      value: "amount",
    },
    {
      label: "Reserved",
      value: "reserved",
    },
    {
      label: "Preallocated",
      value: "preAllocated",
    },
    {
      label: "Redeemed",
      value: "redeemed",
    },
    {
      label: "Avaliable",
      value: "available",
    },
    {
      label: "Created at",
      value: "createdAt",
      sorting: true,
      render: (data: any, columnValue) => formatDateTime(data[columnValue]),
    },
    {
      label: "Actions",
      value: "actions",
      render: (data) => {
        return !data.reserved &&
          !data.redeemed &&
          data.status !== POOL_ACTIVE ? (
          <div className="actions">
            <Copy
              className="cursor-pointer"
              onClick={() => onCopyClick(data.id)}
              title="Copy"
            />
            <Edit
              className="cursor-pointer"
              onClick={() => edit(data.id)}
              title="Edit"
            />
            <Delete
              className="cursor-pointer"
              onClick={() => {
                onDelete(data.id);
              }}
              title="Delete"
            />
          </div>
        ) : (
          <div className="actions">
            <Copy
              className="cursor-pointer"
              onClick={() => onCopyClick(data.id)}
              title="Copy"
            />
            <Edit
              className="cursor-pointer"
              onClick={() => edit(data.id)}
              title="Edit"
            />
          </div>
        );
      },
    },
  ];

  const checkStatus = (status: number) => {
    switch (status) {
      case POOL_INACTIVE:
        return "inactive";
      case POOL_ACTIVE:
        return "active";
      case POOL_DRAFT:
        return "draft";
      case POOL_DEFAULT:
        return "default";
      case POOL_WALLET:
        return "wallet";
    }
  };

  const renderTableCell = (row: any, column: any, index: number) => {
    return (
      <TableCell key={index}>
        {column.render ? (
          column.render(row, column.value)
        ) : (
          <span className="elipsis-200">{row[column.value]}</span>
        )}
      </TableCell>
    );
  };

  return (
    <ErrorBoundary>
      <Loader isLoading={isLoading}>
        <>
          <Table>
            <TableHead>
              {columns.map(({ label, sorting, value }, index) => (
                <TableHeadCell
                  key={index}
                  heading={label}
                  columnName={value}
                  sorting={sorting}
                  sortBy={sort.by}
                  order={sort.order}
                  setSort={changeSorting}
                />
              ))}
            </TableHead>
            <TableBody>
              {data.length > 0
                ? data.map((row, index) => (
                    <TableRow key={index}>
                      {columns.map((column, i) =>
                        renderTableCell(row, column, i),
                      )}
                    </TableRow>
                  ))
                : ""}
            </TableBody>
            <Pagination
              colSpan={columns.length}
              totalCount={totalCount}
              showPerPage={10}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          </Table>
          {data.length > 0 ? (
            ""
          ) : (
            <EmptyBox
              height={520}
              title="No prize pools added yet!"
              description="Create new prize pool that can have multiple prizes and can be defined as prize group for specific outcome in the game."
            />
          )}
        </>
      </Loader>
    </ErrorBoundary>
  );
};

export default PrizePoolsTable;
