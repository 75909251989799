import React, { type ChangeEvent, useState } from 'react';
import { Combobox } from '@headlessui/react';

import './SearchAutoComplete.scss';
import { ReactComponent as Check } from '../../../assets/icons/ic_check.svg';

interface Props {
  options: Array<{ id: string, label: string }>
  selectedOption: { id: string, label: string }
  handleSelectChange: (e: string) => void
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  placeholder?: string
}

const SearchAutoComplete = ({
  options,
  selectedOption,
  handleSelectChange,
  handleSearch,
  disabled,
  placeholder
}: Props) => {
  const [open, setOpen] = useState(false);

  const onSelect = (value: string) => {
    handleSelectChange(value);
    setOpen(false);
  };

  return (
    <Combobox
      value={selectedOption.label}
      disabled={disabled}
      onChange={(value) => onSelect(value)}
      onClick={() => setOpen(!open)}
      as="div"
      className="search-autocomplete"
    >
      <Combobox.Input
        className="input"
        onChange={(e) => handleSearch(e)}
        placeholder={placeholder}
      />
      {open
        ? (
        <Combobox.Options className="search-autocomplete__menu" static>
          {options.map((option) => (
            <Combobox.Option key={option.id} value={option.id} as={'div'} onClick={() => onSelect(option.id)}
            >
              {({ active, selected }) => (
                <li
                  className={`dropdown__menu--item ${
                    selected ? 'selected' : active ? 'active' : ''
                  }`}
                  title={option.label}
                >
                  {option.label} {selected ? <Check /> : ''}
                </li>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
          )
        : (
            ''
          )}
    </Combobox>
  );
};

export default SearchAutoComplete;
