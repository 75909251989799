import { createSlice, type Dispatch } from '@reduxjs/toolkit';
import { type RootState } from '../store';
import { getCodeById, getCodes } from '../api/codes';
import { type Code } from '../../interfaces/Code';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING } from '../../constants/constants';

export interface PrizesState {
  data: Code[]
  dataCount: number
  status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING
}

const initialState: PrizesState = {
  data: [],
  status: STATUS_IDLE,
  dataCount: 0
};

const codesSlice = createSlice({
  name: 'codes',
  initialState,
  reducers: {
    getCodesStarted: (state) => {
      state.status = STATUS_LOADING;
    },
    getCodesSuccess: (state, action) => {
      state.status = STATUS_IDLE;
      if (action.payload.data) {
        state.data = action.payload.data;
      } else {
        state.data = initialState.data;
        state.dataCount = initialState.dataCount;
      }
    },
    getCodesError: (state, action) => {
      state.status = STATUS_FAILED;
      console.log(action.payload.code, action.payload.message);
    },
    getCodeStarted: (state) => {
      state.status = STATUS_LOADING;
    },
    getCodeSuccess: (state, action) => {
      state.status = STATUS_IDLE;
      if (action.payload.data) {
        state.data = action.payload.data;
      } else {
        state.data = initialState.data;
        state.dataCount = initialState.dataCount;
      }
    },
    getCodeError: (state, action) => {
      state.status = STATUS_FAILED;
      console.log(action.payload.code, action.payload.message);
    }
  }
});

const {
  getCodesStarted,
  getCodesSuccess,
  getCodesError,
  getCodeStarted,
  getCodeSuccess,
  getCodeError
} = codesSlice.actions;

export const selectCodes = (state: RootState) => state.codes.data;
export const selectStatus = (state: RootState) => state.codes.status;

export default codesSlice.reducer;

export const fetchCodes = (batchId: number, searchTerm: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getCodesStarted());
      const result = await getCodes(batchId, searchTerm);
      dispatch(getCodesSuccess(result.data));
    } catch (error) {
      dispatch(getCodesError(error));
    }
  };
};

export const fetchCodeById = (batchId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getCodeStarted());
      const result = await getCodeById(batchId);
      dispatch(getCodeSuccess(result.data));
    } catch (error) {
      dispatch(getCodeError(error));
    }
  };
};
