import { Switch as Toggle } from '@headlessui/react';
import React from 'react';

import './Switch.scss';

interface Props {
  active: boolean
  handleChange: (e: boolean, data: any) => void
  data?: any
}

const Switch = ({ active, handleChange, data }: Props) => {
  return (
    <Toggle
      checked={active}
      onChange={(e) => handleChange(e, data)}
      className={`switch ${active ? 'active' : 'inactive'}`}
    >
      <span
        aria-hidden="true"
        className={`switch__circle ${active ? 'active' : 'inactive'}`}
      />
    </Toggle>
  );
};

export default Switch;
