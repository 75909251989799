import React, { useEffect, useState } from "react";

import "./Batches.scss";
import Dropdown from "../../../shared/Dropdown/Dropdown";
import Button from "../../../shared/Button/Button";

import Modal from "../../../shared/Modal/Modal";
import { useAppDispatch, useAppSelector } from "../../../../data/hooks";
import {
  emptyBatch,
  removeBatch,
  selectDuplicateCodesInfo,
  selectHasDuplicateCodes,
} from "../../../../data/slices/batchSlice";
import {
  emptyBatches,
  fetchBatchesByPrizeId,
} from "../../../../data/slices/batchesSlice";
import Confirm from "../../../shared/Confirm/Confirm";
import {
  DEFAULT_COMMON_CODE_BATCH,
  DEFAULT_INDIVIDUAL_CODE_BATCH,
  prizeCode,
  STATUS_LOADING,
} from "../../../../constants/constants";
import { type Batch, type CommonCodeBatch } from "../../../../interfaces/Batch";
import CommonCodeBatchComponent from "../CommonCodeBatch/CommonCodeBatch";
import { ReactComponent as Plus } from "../../../../assets/icons/plus-green.svg";
import IndividualCodes from "../IndividualCodes/IndividualCodes";
import { fetchPrize } from "../../../../data/slices/prizeSlice";

interface Props {
  data: any;
  onCodeTypeChange: (id: string) => void;
  onCommonCodeBatchChangeCb: (batch: CommonCodeBatch | null) => void;
  errors: { barcode: boolean; amount: boolean; validity: boolean };
}

interface individualBatch {
  id: null;
  filename: string;
  file: string;
  expiration: string;
  codeValidity: string;
  codeType: string;
  batchType: string;
}

const Batches: React.FC<Props> = ({
  data,
  onCodeTypeChange,
  onCommonCodeBatchChangeCb,
  errors,
}: Props) => {
  const options = [
    {
      id: prizeCode.INDIVIDUAL_CODE,
      label: "Individual code",
    },
    {
      id: prizeCode.COMMON_CODE,
      label: "Common code",
    },
  ];

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [individualBatches, setIndividualBatches] = useState<individualBatch[]>(
    [],
  );
  const [deletingBatch, setDeletingBatch] = useState(0);
  const [codeTypeOption, setCodeTypeOption] = useState(data.codeType);
  const [commonCodeBatch, setCommonCodeBatch] = useState<CommonCodeBatch>(
    DEFAULT_COMMON_CODE_BATCH,
  );
  const [isDeletingDuplicateCodesBatch, setIsDeletingDuplicateCodesBatch] =
    useState(false);
  const batches = useAppSelector((state: any) =>
    state.batches.data.filter((b: Batch) => b.batchType === codeTypeOption),
  );
  const batchesStatus = useAppSelector((state: any) => state.batches.status);
  const status = useAppSelector((state: any) => state.batch.status);
  const apiErrors = useAppSelector((state: any) =>
    codeTypeOption === prizeCode.INDIVIDUAL_CODE
      ? state.batch.errors
      : state.prize.errors,
  );
  const hasDuplicateCodes = useAppSelector(selectHasDuplicateCodes);
  const duplicateCodes = useAppSelector(selectDuplicateCodesInfo);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data.id) {
      dispatch(fetchBatchesByPrizeId(data.id));
    }
  }, [dispatch, data.id]);

  useEffect(() => {
    if (
      commonCodeBatch.id === null &&
      batches &&
      batches.length === 1 &&
      batches[0].batchType === prizeCode.COMMON_CODE
    ) {
      setCommonCodeBatch((batch) => ({
        ...batch,
        id: +batches[0].id,
        expiration: batches[0].expiration,
        codeValidity: +batches[0].codeValidity,
        codeType: batches[0].codeType,
        commonCode: batches[0].commonCode,
        codesAmount: batches[0].codesAmount,
      }));
    }
    if (
      batches &&
      batches.length > 0 &&
      batches[0].batchType === prizeCode.INDIVIDUAL_CODE
    ) {
      setIndividualBatches(batches);
    } else {
      setIndividualBatches([]);
    }
  }, [batches.length]);

  useEffect(() => {
    onCommonCodeBatchChangeCb(
      codeTypeOption === prizeCode.COMMON_CODE ? commonCodeBatch : null,
    );
  }, [onCommonCodeBatchChangeCb, codeTypeOption, commonCodeBatch]);

  const addEmptyBatch = () => {
    setIndividualBatches((batch) => [...batch, DEFAULT_INDIVIDUAL_CODE_BATCH]);
  };

  const handleCodeTypeChange = (value: any) => {
    setCodeTypeOption(value.id);
    onCodeTypeChange(value.id);
    if (data.id) {
      const type = "";
      dispatch(fetchBatchesByPrizeId(data.id, type));
    } else {
      dispatch(emptyBatches());
    }
  };

  const getSelectedCodeType = () => {
    return (
      options.filter((option) => option.id === codeTypeOption)[0] ?? options[1]
    );
  };

  const deleteAlert = (batch: Batch, selected: number) => {
    const leave = individualBatches.filter((row, index) => {
      return index !== selected;
    });

    if (batch.id) {
      setDeletingBatch(batch.id);
      setIsAlertOpen(true);
    } else {
      setIndividualBatches(leave);
    }
  };

  const deleteBatch = () => {
    const leave = individualBatches.filter((row) => {
      return row.id !== deletingBatch;
    });
    dispatch(removeBatch(deletingBatch)).then(() => {
      setIsAlertOpen(false);
      dispatch(fetchBatchesByPrizeId(data.id));
      dispatch(fetchPrize(data.id));
    });
    setIndividualBatches(leave);
  };

  const onCommonCodeBatchChange = (data: Partial<CommonCodeBatch>) => {
    setCommonCodeBatch((batch) => ({
      ...batch,
      ...data,
    }));
  };

  const onDuplicateCodesDialogClose = () => {
    dispatch(emptyBatch());
  };

  const deleteDuplicateCodesBatch = () => {
    if (!isDeletingDuplicateCodesBatch && duplicateCodes && hasDuplicateCodes) {
      setIsDeletingDuplicateCodesBatch(true);

      dispatch(removeBatch(duplicateCodes.batchId)).then(() => {
        setIsDeletingDuplicateCodesBatch(false);
        dispatch(fetchBatchesByPrizeId(data.id));
      });
    }
  };

  return (
    <div className="codes">
      <p className="codes__description">
        Codes and limits of a prize refer to the specific rules and restrictions
        that apply to the prize.
      </p>
      <div className="codes__header">
        <div className="codes__header--dropdown">
          <Dropdown
            data={options}
            selectedValue={getSelectedCodeType()}
            handleChange={handleCodeTypeChange}
          />
        </div>

        <Button
          type="secondary"
          onClick={() => {
            addEmptyBatch();
          }}
          disabled={codeTypeOption === prizeCode.COMMON_CODE}
        >
          <Plus /> Add batch
        </Button>
      </div>

      <div className="codes__body">
        {codeTypeOption === prizeCode.INDIVIDUAL_CODE ? (
          <IndividualCodes
            data={individualBatches}
            prizeId={data.id}
            isLoading={batchesStatus === STATUS_LOADING}
            deleteBatch={deleteAlert}
          />
        ) : (
          ""
        )}

        {apiErrors?.UNIQUE_CODES_MIN_LENGTH && (
          <span className="error-message">
            None of the uploaded codes are unique.
          </span>
        )}

        {codeTypeOption === prizeCode.COMMON_CODE ? (
          <CommonCodeBatchComponent
            batch={commonCodeBatch}
            onDataChange={onCommonCodeBatchChange}
            errors={errors}
          />
        ) : (
          ""
        )}

        {apiErrors?.UNIQUE_CODE && (
          <span className="error-message">Code already exists.</span>
        )}
      </div>

      <Confirm
        isOpen={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        title="Are you sure?"
        text="Your changes won’t be saved."
        buttonText="Delete"
        type="danger"
        action={deleteBatch}
        isLoading={status === STATUS_LOADING}
      />

      <Modal
        isOpen={hasDuplicateCodes}
        setIsOpen={onDuplicateCodesDialogClose}
        title="Duplicate codes found"
        customClass="duplicate-codes__dialog"
      >
        <div className="duplicate-codes__message">
          {duplicateCodes?.savedCodesCount} unique codes out of{" "}
          {duplicateCodes?.sentCodesCount} saved.
        </div>

        <div className="modal__footer">
          <div className="modal__footer--btns">
            <Button
              type="secondary"
              onClick={() => {
                deleteDuplicateCodesBatch();
              }}
              disabled={isDeletingDuplicateCodesBatch}
              isLoading={isDeletingDuplicateCodesBatch}
            >
              Undo
            </Button>
            <Button
              type="primary"
              onClick={() => {
                onDuplicateCodesDialogClose();
              }}
              disabled={isDeletingDuplicateCodesBatch}
            >
              Finish
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Batches;
