import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import "./BatchUpload.scss";
import { ReactComponent as Delete } from "../../../../assets/icons/delete.svg";
import { ReactComponent as Eye } from "../../../../assets/icons/eye.svg";
import { ReactComponent as Save } from "../../../../assets/icons/save.svg";
import { ReactComponent as Saved } from "../../../../assets/icons/ic_check.svg";
import Input from "../../../shared/Input/Input";
import Dropdown from "../../../shared/Dropdown/Dropdown";
import DatePicker from "react-datepicker";
import { type Option } from "../../../../interfaces/Option";
import FileUpload from "../../../shared/FileUpload/FileUpload";
import {
  barcodeType,
  prizeCode,
  STATUS_LOADING,
} from "../../../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../../../data/hooks";
import { fetchBatchesByPrizeId } from "../../../../data/slices/batchesSlice";
import { addBatch, updateBatch } from "../../../../data/slices/batchSlice";
import Modal from "../../../shared/Modal/Modal";
import CodesList from "../CodesList/CodesList";
import Button from "../../../shared/Button/Button";
import { type Batch } from "../../../../interfaces/Batch";
import { modal } from "../../../../data/slices/notificationSlice";
import { AvailabilityContext } from "../Prizes/Prizes";

interface Props {
  data: any;
  index: number;
  onDelete: (batchData: Batch, index: number) => void;
  prizeId: number;
}

const errorsInitialState = {
  file: false,
  expiration: false,
  codeValidity: false,
};

const options = [
  {
    id: barcodeType.EAN128,
    label: "EAN128",
  },
  {
    id: barcodeType.EAN13,
    label: "EAN13",
  },
  {
    id: barcodeType.QR,
    label: "QR",
  },
  {
    id: barcodeType.TEXT,
    label: "Text",
  },
];

const BatchUpload: React.FC<Props> = ({
  data,
  index,
  onDelete,
  prizeId,
}: Props) => {
  const [batchData, setBatchData] = useState(data);
  const [filename, setFilename] = useState("");
  const [date, setDate] = useState(new Date());
  const [isOpenListModal, setIsOpenListModal] = useState(false);
  const [errors, setErrors] = useState(errorsInitialState);
  const [dataChanged, setDataChanged] = useState(false);
  const status = useAppSelector((state: any) => state.batch.status);
  const { availability, setAvailability } = useContext(AvailabilityContext);

  const isExistingBatch = !!data.id;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (batchData.expiration) {
      setDate(batchData.expiration);
    }
    if (data.filename) {
      setFilename(data.filename);
    }

    if (
      data.expiration !== batchData.expiration ||
      data.codeValidity !== batchData.codeValidity
    ) {
      setDataChanged(true);
    }
    if (batchData.id !== data.id) {
      setBatchData(data);
      setAvailability({
        amount: availability.amount + data.codesAmount,
        available: availability.available + data.available,
      });
    }
  }, [data, batchData]);

  const onFileChange = (event: any) => {
    event.preventDefault();
    const files = event.target.files;
    if (files[0].type !== "text/csv") {
      return;
    }
    setFilename(files[0].name);
    setBatchData({
      ...batchData,
      file: files[0],
      filename: files[0].name,
    });
  };

  const clearFile = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.preventDefault();
    setFilename("");
    setBatchData({
      ...batchData,
      file: "",
      filename: "",
    });
  };

  const onInputChange = (event: any) => {
    let value = event.target.value;
    const name = event.target.name;
    if (["codeValidity"].includes(event.target.name)) {
      value = Math.abs(Number(event.target.value));
    }
    setBatchData({
      ...batchData,
      [name]: value,
    });
  };

  const handleDateChange = (event: Date | null) => {
    if (event) {
      setDate(event);
      setBatchData({
        ...batchData,
        expiration: event,
      });
    }
  };

  const handleBarCodeChange = (option: Option) => {
    setBatchData({
      ...batchData,
      codeType: String(option.id),
    });
  };

  const getSelectedCodeType = () => {
    return (
      options.filter((option) => option.id === batchData.codeType)[0] ??
      options[0]
    );
  };

  const saveBatch = () => {
    if (!validate()) {
      dispatch(
        modal.notify({
          message:
            "Ensure that all fields are completed and the file is uploaded before proceeding to save the batch.",
          type: "warning",
        }),
      );
      return;
    }

    if (!isExistingBatch) {
      const formData = new FormData();
      formData.append("prizeId", prizeId.toString());
      formData.append("file", batchData.file);
      formData.append("filename", batchData.filename);
      formData.append("expiration", batchData.expiration.toString());
      formData.append("codeValidity", batchData.codeValidity.toString());
      formData.append("codeType", batchData.codeType);
      formData.append("batchType", prizeCode.INDIVIDUAL_CODE);
      dispatch(addBatch(formData)).then(() => {
        dispatch(fetchBatchesByPrizeId(prizeId));
      });
    } else {
      const batchUpdateDto = {
        expiration: new Date(batchData.expiration).toISOString(),
        codeValidity: batchData.codeValidity,
        codeType: batchData.codeType,
        batchType: batchData.batchType,
      };

      dispatch(updateBatch(batchUpdateDto as Batch, batchData.id)).then(() => {
        dispatch(fetchBatchesByPrizeId(prizeId));
      });
    }
  };

  const validate = () => {
    const errors = {
      file: !isExistingBatch && batchData.file.length === 0,
      expiration: !batchData.expiration,
      codeValidity:
        batchData.codeValidity === 0 || batchData.codeValidity > 1000000,
    };
    setErrors(errors);
    return Object.values(errors).every((value) => !value);
  };

  return (
    <div
      className={`codes-upload ${status === STATUS_LOADING ? "loading" : ""}`}
    >
      <div className="codes-upload__top">
        <p className="codes-upload__title">
          {`Batch ${index + 1}`} {batchData.id ? <Saved title="Saved" /> : ""}
        </p>
        <div className="codes-upload__actions">
          <button
            className="codes-upload__delete"
            onClick={() => onDelete(batchData, index)}
          >
            <Delete />
          </button>
          <button
            className={`codes-upload__view ${!batchData.id ? "disabled" : ""}`}
            disabled={!batchData.id}
            onClick={() => setIsOpenListModal(true)}
          >
            <Eye />
          </button>
          <button
            className={`codes-upload__save ${
              !batchData.file && (!dataChanged || status === STATUS_LOADING)
                ? "disabled"
                : ""
            }`}
            disabled={
              !batchData.file && (!dataChanged || status === STATUS_LOADING)
            }
            onClick={() => saveBatch()}
          >
            <Save /> Save Batch
          </button>
        </div>
      </div>
      <div className="codes-upload__bottom">
        <div className="codes-dropdown">
          <label className="codes-upload__label">Code Type</label>
          <Dropdown
            data={options}
            selectedValue={getSelectedCodeType()}
            handleChange={handleBarCodeChange}
            width={160}
          />
        </div>
        <div className="codes-expiration">
          <label className="codes-upload__label">Code Expiration</label>
          <DatePicker
            selected={new Date(date)}
            minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
            dateFormat="dd/MM/yyyy"
            onChange={(e) => {
              handleDateChange(e);
            }}
          />
        </div>

        <div className="codes-validity">
          <label className="codes-upload__label">Code Validity</label>
          <span>
            <Input
              placeholder="0"
              name="codeValidity"
              type="number"
              min="0"
              errors={errors?.codeValidity}
              value={batchData.codeValidity ?? 0}
              onChange={onInputChange}
            />
          </span>
        </div>
        <FileUpload
          filename={filename}
          handleChange={onFileChange}
          clearFile={(e) => clearFile(e)}
          error={errors.file}
        />
      </div>
      <div className="codes-upload__sum">
        <div>
          <p>Amount: </p>
          <p>{batchData.codesAmount ?? 0}</p>
        </div>
        <div>
          <p>Redeemed: </p>
          <p>{batchData.redeemed ?? 0}</p>
        </div>
        <div>
          <p>Reserved: </p>
          <p>{batchData.reserved ?? 0}</p>
        </div>
        <div>
          <p>Available: </p>
          <p>{batchData.available ?? 0}</p>
        </div>
      </div>

      <Modal
        isOpen={isOpenListModal}
        setIsOpen={setIsOpenListModal}
        title={`List of ${batchData.codeType} codes`}
        customClass="codes-list-modal"
      >
        <CodesList
          batchId={batchData.id}
          codeType={batchData.codeType}
        ></CodesList>
        <div className="modal__footer">
          <div className="modal__footer--btns">
            <Button
              type="primary"
              customClass="btn-small"
              onClick={() => setIsOpenListModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BatchUpload;
