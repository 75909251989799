import React, { useState } from "react";
import Modal from "../../../shared/Modal/Modal";
import Button from "../../../shared/Button/Button";

interface Props {
  availability: {
    amount: number;
    reserved: number;
    redeemed: number;
    available: number;
  };
}

const ModalFooterData = ({ availability }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className="modal__footer--link"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
            stroke="#197B6F"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.25 11.25H12V16.5H12.75"
            stroke="#197B6F"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.8125 8.625C12.2267 8.625 12.5625 8.28921 12.5625 7.875C12.5625 7.46079 12.2267 7.125 11.8125 7.125C11.3983 7.125 11.0625 7.46079 11.0625 7.875C11.0625 8.28921 11.3983 8.625 11.8125 8.625Z"
            fill="#197B6F"
          />
        </svg>
        Current prize availability
      </div>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Current prize avaliability"
        customClass="avaliability-modal"
      >
        <div className="modal-body">
          <div>
            <p>Amount:</p>
            <p className="availablity-value">{availability.amount ?? 0}</p>
          </div>
          <div>
            <p>Redeemed:</p>
            <p className="availablity-value">{availability.redeemed ?? 0}</p>
          </div>
          <div>
            <p>Available:</p>
            <p className="availablity-value">{availability.available ?? 0}</p>
          </div>
          <div>
            <p>Reserved:</p>
            <p className="availablity-value">{availability.reserved ?? 0}</p>
          </div>
        </div>
        <div className="modal__footer">
          <div className="modal__footer--btns">
            <Button
              type="secondary"
              customClass="btn-small"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalFooterData;
