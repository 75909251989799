import { Dialog } from '@headlessui/react';
import React from 'react';
import './Modal.scss';

interface Props {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  title: string
  children: React.ReactNode
  customClass?: string
}

const Modal = ({ isOpen, setIsOpen, title, children, customClass }: Props) => {
  return (
        <Dialog as="div" open={isOpen} onClose={() => setIsOpen} className={`modal ${customClass}`}>

            <div className="modal__backdrop " aria-hidden="true"/>

            <div className="modal__container">

                <Dialog.Panel className="modal__panel">
                    <Dialog.Title>
                        {title}
                    </Dialog.Title>
                    <Dialog.Description as="div" className="modal__description">
                        {children}
                    </Dialog.Description>
                </Dialog.Panel>

            </div>
        </Dialog>
  );
};

export default Modal;
