import React, { useEffect, useState } from "react";
import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Loader from "../../../shared/Loader/Loader";
import Table from "../../../shared/Table/Table";
import TableBody from "../../../shared/Table/TableBody";
import TableHead from "../../../shared/Table/TableHead";
import TableHeadCell from "../../../shared/Table/TableHeadCell";
import TableRow from "../../../shared/Table/TableRow";
import TableCell from "../../../shared/Table/TableCell";
import { type Column } from "../../../../interfaces/Column";
import { type Prize } from "../../../../interfaces/Prize";
import { useAppDispatch, useAppSelector } from "../../../../data/hooks";
import Pagination from "../../../shared/Pagination/Pagination";
import { fetchPrizes } from "../../../../data/slices/prizesSlice";
import Checkbox from "../../../shared/Checkbox/Checkbox";
import {
  METHOD_ADD,
  METHOD_DELETE,
  STATUS_LOADING,
} from "../../../../constants/constants";
import Search from "../../../shared/Search/Search";
import { useAuth } from "../../../../custom-hooks/useAuth";

import noImage from "../../../../assets/images/no-image.svg";

interface Props {
  poolPrizes: Prize[];
  updatePoolPrizes: (prize: Prize[]) => void;
}

const PrizesTable = ({ poolPrizes, updatePoolPrizes }: Props) => {
  const {
    user: { companyId },
  } = useAuth();
  const initialSearchPrizeData = {
    companyId,
    perPage: 8,
    pageNumber: 0,
    sortBy: "id",
    order: "DESC",
    searchTerm: "",
  };
  const prizes: Prize[] = useAppSelector((state: any) => state.prizes.data);
  const totalCount: number = useAppSelector(
    (state: any) => state.prizes.dataCount,
  );
  const status: string = useAppSelector((state: any) => state.prizes.status);
  const [requestData, changeRequestData] = useState(initialSearchPrizeData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPrizes(requestData));
  }, [dispatch, requestData]);

  const columns: Column[] = [
    {
      label: "Select",
      value: "select",
      render: (data, columnValue, index: number) => (
        <Checkbox
          key={index}
          checked={checkIfPrizeInPool(data.id)}
          handleChange={handleCheckboxChange}
          value={data.id}
        />
      ),
    },
    {
      label: "ID",
      value: "internalPrizeId",
    },
    {
      label: "Title",
      value: "title",
    },
   {
      label: 'Image',
      value: 'gameImage',
      render: (data, columnValue) => {
        return (
            <img
                src={data[columnValue] || noImage}
                alt="Prize"
            />
        );
      }
    },
    {
      label: "Available",
      value: "available",
    },
  ];
  const handleCheckboxChange = (e: any) => {
    const method = e.target.checked ? METHOD_ADD : METHOD_DELETE;
    const prize = prizes.find((prize) => prize.id === Number(e.target.value));
    if (method === METHOD_DELETE && prize) {
      const prizesInsidePool = poolPrizes.filter(
        (poolPrize) => poolPrize.id !== prize.id,
      );
      updatePoolPrizes(prizesInsidePool);
      return;
    }

    const prizesInsidePool = [...poolPrizes];
    if (prize) {
      prizesInsidePool.unshift(prize);
      updatePoolPrizes(prizesInsidePool);
    }
  };

  const checkIfPrizeInPool = (id: number) => {
    return poolPrizes.some((prize) => prize.id === id);
  };

  const onPageChange = (pageNumber: string | number) => {
    const page = pageNumber !== 0 ? +pageNumber - 1 : pageNumber;
    changeRequestData({
      ...requestData,
      pageNumber: page,
    });
  };

  const search = (searchTerm: string) => {
    changeRequestData({
      ...requestData,
      searchTerm,
      pageNumber: 0,
    });
  };

  const renderTableCell = (
    row: any,
    column: any,
    columnIndex: number,
    rowIndex: number,
  ) => {
    return (
      <TableCell key={columnIndex}>
        {column.render ? (
          column.render(row, column.value, rowIndex)
        ) : (
          <span className="elipsis-200">{row[column.value]}</span>
        )}
      </TableCell>
    );
  };

  return (
    <ErrorBoundary>
      <div className="add-prizes-header">
        <Search search={search} />
      </div>
      <Loader isLoading={status === STATUS_LOADING} height={415}>
        <Table>
          <TableHead transparent={true}>
            {columns.map(({ label }, index) => (
              <TableHeadCell key={index} heading={label} />
            ))}
          </TableHead>
          <TableBody>
            {prizes.length > 0 ? (
              prizes.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column, i) =>
                    renderTableCell(row, column, i, index),
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <td className="no-data" colSpan={columns.length}>
                  No prizes available
                </td>
              </TableRow>
            )}
          </TableBody>
          <Pagination
            colSpan={columns.length}
            totalCount={totalCount}
            showPerPage={10}
            currentPage={requestData.pageNumber + 1}
            onPageChange={onPageChange}
          />
        </Table>
      </Loader>
    </ErrorBoundary>
  );
};

export default PrizesTable;
