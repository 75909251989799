import { configureStore, type ThunkAction, type Action } from '@reduxjs/toolkit';
import prizesReducer from './slices/prizesSlice';
import prizeReducer from './slices/prizeSlice';
import winnersReducer from './slices/winnersSlice';
import poolsReducer from './slices/poolsSlice';
import resendPrizeReducer from './slices/resendPrizeSlice';
import redeemPrizeReducer from './slices/redeemPrizeSlice';
import codesReducer from './slices/codesSlice';
import poolReducer from './slices/poolSlice';
import batchReducer from './slices/batchSlice';
import batchesReducer from './slices/batchesSlice';
import campaignsReducer from './slices/campaignsSlice';
import campaignReducer from './slices/campaignSlice';
import winnerPrizesReducer from './slices/winnerPrizes';
import generateWinReducer from './slices/generateWinSlice';
import notificationReducer from './slices/notificationSlice';
import importPrizesReducer from './slices/importPrizesSlice';
import copyReducer from './slices/copySlice';

export const store = configureStore({
  reducer: {
    prizes: prizesReducer,
    prize: prizeReducer,
    importPrizes: importPrizesReducer,
    winners: winnersReducer,
    winnerPrizes: winnerPrizesReducer,
    pools: poolsReducer,
    pool: poolReducer,
    copy: copyReducer,
    resendPrize: resendPrizeReducer,
    redeemPrize: redeemPrizeReducer,
    codes: codesReducer,
    batch: batchReducer,
    batches: batchesReducer,
    campaigns: campaignsReducer,
    campaign: campaignReducer,
    notifications: notificationReducer,
    win: generateWinReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> =
    ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
    >;
