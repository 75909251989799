import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import Home from "./pages/Home/Home";
import WinnerSearch from "./pages/WinnerSearch/WinnerSearch";
import ProtectedRoute from "./components/shared/ProtectedRoute/ProtectedRoute";
import NotFound from "./pages/NotFound/NotFound";
import {AuthProvider} from "./custom-hooks/useAuth";
import AuthLoader from "./components/shared/AuthLoader/AuthLoader";

const App = () => {
  return (
    <BrowserRouter basename="/prize-management">
      <AuthProvider>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                <AuthLoader>
                  <ProtectedRoute
                    authenticationPath={process.env.REACT_APP_AUTHENTICATION_URL}
                    outlet={<Home/>}
                  />
                </AuthLoader>
              }
            />
            <Route path="/search-winner/:campaignId" element={<WinnerSearch/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
