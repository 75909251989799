import { createSlice, type Dispatch } from '@reduxjs/toolkit';
import { createPrize, deletePrize, getPrize, putPrize } from '../api/prizes';
import { type Prize } from '../../interfaces/Prize';
import { type RootState } from '../store';
import { modal } from './notificationSlice';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING } from '../../constants/constants';
import { saveCommonCodeBatch } from '../api/batches';
import { type CommonCodeBatch } from '../../interfaces/Batch';
import { parseApiErrors } from '../../util/util';

export interface PrizeState {
  data: Prize[]
  status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING
  errors: Record<string, boolean> | null
}

const initialState: PrizeState = {
  data: [],
  status: STATUS_IDLE,
  errors: null
};

const prizeSlice = createSlice({
  name: 'prize',
  initialState,
  reducers: {
    getPrizeStarted: (state) => {
      state.status = STATUS_LOADING;
      state.errors = null;
    },
    getPrizeSuccess: (state, action) => {
      state.status = STATUS_IDLE;
      if (action.payload.data) {
        state.data = action.payload.data[0];
      } else {
        state.data = initialState.data;
      }
    },
    getPrizeError: (state, action) => {
      state.status = STATUS_FAILED;
      console.log(action.payload.code, action.payload.message);
    },
    createPrizeStarted: (state) => {
      state.status = STATUS_LOADING;
      state.errors = null;
    },
    createPrizeSuccess: (state, action) => {
      state.status = STATUS_IDLE;
      if (action.payload.data) {
        state.data = action.payload.data[0];
      } else {
        state.data = initialState.data;
      }
    },
    createPrizeError: (state, action) => {
      state.status = STATUS_FAILED;
      console.log(action.payload.message);
    },
    updatePrizeStarted: (state) => {
      state.status = STATUS_LOADING;
      state.errors = null;
    },
    updatePrizeSuccess: (state, action) => {
      state.status = STATUS_IDLE;
      if (action.payload.data) {
        state.data = action.payload.data;
      } else {
        state.data = initialState.data;
      }
    },
    updatePrizeError: (state, action) => {
      state.status = STATUS_FAILED;
      state.errors = action.payload;
      console.log(action.payload.message);
    },
    deletePrizeStarted: (state) => {
      state.status = STATUS_LOADING;
      state.errors = null;
    },
    deletePrizeSuccess: (state, action) => {
      if (action.payload.data) {
        state.data = action.payload.data[0];
      } else {
        state.data = initialState.data;
      }
      state.status = STATUS_IDLE;
    },
    deletePrizeError: (state, action) => {
      state.status = STATUS_FAILED;
      console.log(action.payload.code, action.payload.message);
    },
    getEmptyPrize: (state, action) => {
      state.status = STATUS_IDLE;
      state.data = [];
      state.errors = null;
    }
  }
});

const {
  getPrizeStarted,
  getPrizeSuccess,
  getPrizeError,
  createPrizeStarted,
  createPrizeSuccess,
  createPrizeError,
  updatePrizeStarted,
  updatePrizeSuccess,
  updatePrizeError,
  deletePrizeStarted,
  deletePrizeSuccess,
  deletePrizeError,
  getEmptyPrize
} = prizeSlice.actions;

export const selectPrize = (state: RootState) => state.prize.data;
export const selectStatus = (state: RootState) => state.prize.status;

export default prizeSlice.reducer;

export const fetchPrize = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getPrizeStarted());
      const result = await getPrize(id);
      dispatch(getPrizeSuccess(result.data));
    } catch (error) {
      dispatch(getPrizeError(error));
    }
  };
};

export const addPrize = (data: Prize) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(createPrizeStarted());
      const result = await createPrize(data);
      dispatch(createPrizeSuccess(result.data));
      dispatch(
        modal.notify({
          message: 'Prize successfully saved.',
          type: 'success'
        })
      );
    } catch (error) {
      dispatch(createPrizeError(error));
      dispatch(
        modal.notify({
          message: 'Something went wrong.',
          type: 'error'
        })
      );
    }
  };
};

export const updatePrize = (data: any, id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updatePrizeStarted());

      if (data.commonCodeBatch) {
        await saveCommonCodeBatch(id, data.commonCodeBatch as CommonCodeBatch);
      }

      const result = await putPrize(data, id);

      dispatch(updatePrizeSuccess(result.data));
      dispatch(
        modal.notify({
          message: 'Prize successfully updated.',
          type: 'success'
        })
      );
    } catch (error) {
      const validationErrors = parseApiErrors(error as any);

      dispatch(updatePrizeError(validationErrors));

      if (!validationErrors) {
        dispatch(
          modal.notify({
            message: 'Something went wrong.',
            type: 'error'
          })
        );
      }
    }
  };
};

export const removePrize = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deletePrizeStarted());
      const result = await deletePrize(id);
      dispatch(deletePrizeSuccess(result.data));
    } catch (error) {
      dispatch(deletePrizeError(error));
    }
  };
};

export const emptyPrize = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmptyPrize([]));
  };
};
