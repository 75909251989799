import React from 'react';

import ErrorBoundary from '../../../shared/ErrorBoundary/ErrorBoundary';
import Loader from '../../../shared/Loader/Loader';
import Table from '../../../shared/Table/Table';
import TableBody from '../../../shared/Table/TableBody';
import TableHead from '../../../shared/Table/TableHead';
import TableHeadCell from '../../../shared/Table/TableHeadCell';
import TableRow from '../../../shared/Table/TableRow';
import TableCell from '../../../shared/Table/TableCell';
import { type Column } from '../../../../interfaces/Column';
import { ReactComponent as Delete } from '../../../../assets/icons/delete.svg';
import { type Prize } from '../../../../interfaces/Prize';
import Switch from '../../../shared/Switch/Switch';
import { updatePrize } from '../../../../data/slices/prizeSlice';
import { useAppDispatch } from '../../../../data/hooks';
import EmptyBox from '../../../shared/EmptyBox/EmptyBox';
import noImage from "../../../../assets/images/no-image.svg";

interface Props {
  data: Prize[]
  openDeleteAlert: (id: number) => void
  toggleEnabled: (active: boolean, id: number) => void
}

const InsidePoolTable: React.FC<Props> = ({
  data,
  openDeleteAlert,
  toggleEnabled
}: Props) => {
  const dispatch = useAppDispatch();

  const columns: Column[] = [
    {
      label: 'Enabled',
      value: 'active',
      render: (data, columnValue) => (
        <Switch
          active={!data[columnValue]}
          handleChange={toggleSwitch}
          data={data}
        />
      )
    },
    {
      label: 'ID',
      value: 'internalPrizeId'
    },
    {
      label: 'Image',
      value: 'gameImage',
      render: (data, columnValue) => {
        return (
            <img
                src={data[columnValue] || noImage}
                alt="Prize"
            />
        );
      }
    },
    {
      label: 'Title',
      value: 'title'
    },
    {
      label: 'Amount',
      value: 'amount'
    },
    {
      label: 'Avaliable',
      value: 'available'
    },
    {
      label: 'Actions',
      value: 'delete',
      render: (data) => <Delete onClick={() => openDeleteAlert(data.id)} />
    }
  ];

  const toggleSwitch = (active: boolean, data: Prize) => {

    if (data?.id) {
      const prize = { ...data };
      prize.active = active ? 0 : 1;
      prize.amount = data.amount ?? 0;
      prize.resultsImageFilename = prize.resultsImageFilename ?? '';
      prize.gameImageFilename = prize.gameImageFilename ?? '';
      dispatch(updatePrize(prize, data.id));
      toggleEnabled(active, data.id);
    }
  };

  const renderTableCell = (row: any, column: any, index: number) => {
    return (
      <TableCell key={index}>
        {column.render
          ? (
              column.render(row, column.value)
            )
          : (
          <span className="elipsis-100">{row[column.value]}</span>
            )}
      </TableCell>
    );
  };

  return (
    <ErrorBoundary>
      <Loader isLoading={false}>
        {data.length > 0
          ? (
          <Table>
            <TableHead transparent={true}>
              {columns.map(({ label, infoText }, index) => (
                <TableHeadCell
                  key={index}
                  heading={label}
                  infoText={infoText ?? ''}
                />
              ))}
            </TableHead>
            <TableBody>
              {data.length > 0
                ? (
                    data.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column, i) =>
                      renderTableCell(row, column, i)
                    )}
                  </TableRow>
                    ))
                  )
                : (
                <TableRow>
                  <td className="no-data" colSpan={columns.length}>
                    No data
                  </td>
                </TableRow>
                  )}
            </TableBody>
          </Table>
            )
          : (
          <EmptyBox height={390} title={'No prizes added yet.'} />
            )}
      </Loader>
    </ErrorBoundary>
  );
};

export default InsidePoolTable;
