import React, { useEffect, useState } from 'react';
import './Notification.scss';
import { useAppDispatch, useAppSelector } from '../../../data/hooks';
import { modal } from '../../../data/slices/notificationSlice';
import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { ReactComponent as Check } from '../../../assets/icons/ic_check.svg';
import { ReactComponent as Error } from '../../../assets/icons/warning.svg';
import { ReactComponent as Warning } from '../../../assets/icons/info.svg';

const initialNotificationState = {
  type: '',
  message: ''
};

const Notifcation = () => {
  const data = useAppSelector((state: any) => state.notifications);
  const [notification, setNotification] = useState(initialNotificationState);
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data.notifications.length > 0) {
      setNotification(data.notifications[data.notifications.length - 1]);
      setShow(true);
      setTimeout(() => {
        setShow(false);
        dispatch(modal.emptyNotifications());
      }, 4000);
    }
  }, [data, dispatch]);

  const onClose = () => {
    setShow(false);
  };

  const getNotificationIcon = () => {
    switch (notification.type) {
      case 'success':
        return <Check />;
      case 'error':
        return <Error />;
      case 'warning':
        return <Warning />;
    }
  };

  return (
    <div className="notification-container">
      <div className={`notification ${show ? notification.type : 'hidden'}`}>
        {getNotificationIcon()}
        <div className="notification--message">
          <p>{notification.message}</p>
        </div>
        <Close onClick={onClose} />
      </div>
    </div>
  );
};

export default Notifcation;
