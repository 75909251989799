import { Dialog } from "@headlessui/react";
import React from "react";

import Button from "../Button/Button";
import "./Confirm.scss";

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  text: string;
  type: "primary" | "danger";
  buttonText: string;
  action: () => void;
  isLoading?: boolean;
}

const Confirm = (props: Props) => {
  const {
    isOpen,
    setIsOpen,
    title,
    text,
    type,
    buttonText,
    action,
    isLoading,
  } = props;
  return (
    <Dialog
      as="div"
      open={isOpen}
      onClose={() => setIsOpen}
      className={`confirm ${type}`}
    >
      <div className="confirm__backdrop " aria-hidden="true" />

      <div className="confirm__container">
        <Dialog.Panel className="confirm__panel">
          <Dialog.Title>{title}</Dialog.Title>
          <Dialog.Description as="div" className="confirm__description">
            <p>{text}</p>
          </Dialog.Description>
          <div className="confirm__footer">
            <Button
              type={type}
              customClass="btn-small"
              onClick={() => action()}
              isLoading={isLoading}
              disabled={isLoading}
              dataTestId="confirm-button"
            >
              {buttonText}
            </Button>
            <Button
              type="secondary"
              customClass="btn-small"
              onClick={() => setIsOpen(false)}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default Confirm;
