import { createSlice, type Dispatch } from '@reduxjs/toolkit';
import { getPools, getPoolsByCompany, searchPools, deletePool as _deletePool } from '../api/pools';
import { type Pool } from '../../interfaces/Pool';
import { type RootState } from '../store';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING } from '../../constants/constants';

export interface PoolsState {
  data: Pool[]
  dataCount: number
  status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING
}

const initialState: PoolsState = {
  data: [],
  status: STATUS_IDLE,
  dataCount: 0
};

const poolsSlice = createSlice({
  name: 'pools',
  initialState,
  reducers: {
    searchPoolsStarted: (state) => {
      state.status = STATUS_LOADING;
    },
    searchPoolsSuccess: (state, action) => {
      state.status = STATUS_IDLE;
      if (action.payload.data) {
        state.data = action.payload.data;
        state.dataCount = action.payload.dataCount;
      } else {
        state.data = initialState.data;
        state.dataCount = initialState.dataCount;
      }
    },
    searchPoolsError: (state, action) => {
      state.status = STATUS_FAILED;
      console.log(action.payload.code, action.payload.message);
    },
    getPoolsStarted: (state) => {
      state.status = STATUS_LOADING;
    },
    getPoolsSuccess: (state, action) => {
      state.status = STATUS_IDLE;
      if (action.payload.data) {
        state.data = action.payload.data;
      } else {
        state.data = initialState.data;
      }
    },
    getPoolsError: (state, action) => {
      state.status = 'failed';
      console.log(action.payload.code, action.payload.message);
    },
    getPoolsByCompanyStarted: (state) => {
      state.status = STATUS_LOADING;
    },
    getPoolsByCompanySuccess: (state, action) => {
      state.status = STATUS_IDLE;
      if (action.payload.data) {
        state.data = action.payload.data;
      } else {
        state.data = initialState.data;
      }
    },
    getPoolsByCompanyError: (state, action) => {
      state.status = STATUS_FAILED;
      console.log(action.payload.code, action.payload.message);
    },
    deletePoolStarted: (state) => {
      // TODO
    },
    deletePoolSucceeded: (state) => {
      // TODO
    },
    deletePoolFailed: (state) => {
      // TODO
    }
  }
});

const {
  searchPoolsStarted,
  searchPoolsSuccess,
  searchPoolsError,
  getPoolsStarted,
  getPoolsSuccess,
  getPoolsError,
  getPoolsByCompanyStarted,
  getPoolsByCompanySuccess,
  getPoolsByCompanyError,
  deletePoolStarted,
  deletePoolSucceeded,
  deletePoolFailed
} = poolsSlice.actions;

export const selectPools = (state: RootState) => state.pools.data;
export const selectDataCount = (state: RootState) => state.pools.dataCount;
export const selectStatus = (state: RootState) => state.pools.status;

export default poolsSlice.reducer;

export const fetchPools = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(searchPoolsStarted());
      const result = await searchPools(data);
      dispatch(searchPoolsSuccess(result.data));
    } catch (error) {
      dispatch(searchPoolsError(error));
    }
  };
};

export const fetchPoolsByCampaignid = (campaignId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getPoolsStarted());
      const result = await getPools(campaignId);
      dispatch(getPoolsSuccess(result.data));
    } catch (error) {
      dispatch(getPoolsError(error));
    }
  };
};

export const fetchPoolsByCompanyid = (companyId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getPoolsByCompanyStarted());
      const result = await getPoolsByCompany(companyId);
      dispatch(getPoolsByCompanySuccess(result.data));
    } catch (error) {
      dispatch(getPoolsByCompanyError(error));
    }
  };
};

export const deletePool = (poolId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deletePoolStarted());
      await _deletePool(poolId);
      dispatch(deletePoolSucceeded());
    } catch (e) {
      dispatch(deletePoolFailed());
    }
  };
};
