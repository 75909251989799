import React, { useState } from "react";

import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Table from "../../../shared/Table/Table";
import TableBody from "../../../shared/Table/TableBody";
import TableHead from "../../../shared/Table/TableHead";
import TableHeadCell from "../../../shared/Table/TableHeadCell";
import TableRow from "../../../shared/Table/TableRow";
import TableCell from "../../../shared/Table/TableCell";
import { type Column } from "../../../../interfaces/Column";
import Button from "../../../shared/Button/Button";
import Modal from "../../../shared/Modal/Modal";
import InPoolsAdd from "../InPoolsAdd/InPoolsAdd";
import { ReactComponent as Delete } from "../../../../assets/icons/delete.svg";
import EmptyBox from "../../../shared/EmptyBox/EmptyBox";
import { type Pool } from "../../../../interfaces/Pool";

interface Props {
  data: any[];
  isOpenAddToPoolModal: boolean;
  setIsAddToPoolModal: (open: boolean) => void;
  handleOnChange: (pool: Pool | null) => void;
}

const InPoolsTable: React.FC<Props> = ({
  data,
  isOpenAddToPoolModal,
  setIsAddToPoolModal,
  handleOnChange,
}: Props) => {
  const initialState = data.length ? data[0] : { id: 0 };
  const [selectedPool, setSelectedPool] = useState(initialState);

  const columns: Column[] = [
    {
      label: "Pool ID",
      value: "internalPoolId",
    },
    {
      label: "Pool Title",
      value: "title",
    },
    {
      label: "Image",
      value: "image",
      render: (data: any, columnValue: string) =>
        data[columnValue] ? (
          <img src={data[columnValue]} alt="Pool" />
        ) : (
          <img
            src={require("./../../../../assets/images/white.png")}
            alt="Pool"
          />
        ),
    },
    {
      label: "Available",
      value: "available",
      render: (data: any, columnValue: string) => data[columnValue] ?? 0,
    },
    {
      label: "Actions",
      value: "delete",
      render: () => (
        <Delete
          onClick={() => handleOnChange(null)}
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];

  const renderTableCell = (row: any, column: any, index: number) => {
    return (
      <TableCell key={index}>
        {column.render ? (
          column.render(row, column.value)
        ) : (
          <span className="elipsis-200">{row[column.value]}</span>
        )}
      </TableCell>
    );
  };

  const save = () => {
    handleOnChange(selectedPool);
  };

  return (
    <ErrorBoundary>
      {data.length > 0 ? (
        <Table>
          <TableHead transparent={true}>
            {columns.map(({ label }, index) => (
              <TableHeadCell key={index} heading={label} />
            ))}
          </TableHead>
          <TableBody>
            {data.length > 0
              ? data.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column, i) =>
                      renderTableCell(row, column, i),
                    )}
                  </TableRow>
                ))
              : ""}
          </TableBody>
        </Table>
      ) : (
        <EmptyBox height={390} title={"No added pools yet."} />
      )}
      <Modal
        isOpen={isOpenAddToPoolModal}
        setIsOpen={setIsAddToPoolModal}
        title="Add to pool"
        customClass="codes-edit-modal"
      >
        <InPoolsAdd
          selectedPool={selectedPool.id}
          setSelectedPool={setSelectedPool}
        ></InPoolsAdd>
        <div className="modal__footer">
          <div className="modal__footer--btns">
            <Button
              type="secondary"
              onClick={() => setIsAddToPoolModal(!isOpenAddToPoolModal)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                save();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </ErrorBoundary>
  );
};

export default InPoolsTable;
