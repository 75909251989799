import { Dialog } from "@headlessui/react";
import React from "react";

import Button from "../Button/Button";
import "./Alert.scss";

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  text: string;
  type: "primary" | "danger";
}

const Alert = (props: Props) => {
  const { isOpen, setIsOpen, title, text, type } = props;
  return (
    <Dialog
      as="div"
      open={isOpen}
      onClose={() => setIsOpen}
      className={`alert ${type}`}
    >
      <div className="alert__backdrop " aria-hidden="true" />

      <div className="alert__container">
        <Dialog.Panel className="alert__panel">
          <Dialog.Title>{title}</Dialog.Title>
          <Dialog.Description as="div" className="alert__description">
            {text}
          </Dialog.Description>
          <div className="alert__footer">
            <Button
              type={type}
              customClass="btn-small"
              onClick={() => setIsOpen(!isOpen)}
            >
              Close
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default Alert;
