import React, { useContext, useEffect } from "react";

import "./Timeline.scss";
import { type Pool } from "../../../interfaces/Pool";
import {
  FIRST_VALUE,
  LIMIT_DIFFERENT,
  LIMIT_UNLIMITED,
  PROBABILITY_DIFFERENT,
  SECOND_VALUE,
} from "../../../constants/constants";
import DoubleInput from "../DoubleInput/DoubleInput";
import Table from "../Table/Table";
import TableHead from "../Table/TableHead";
import TableHeadCell from "../Table/TableHeadCell";
import TableBody from "../Table/TableBody";
import TableRow from "../Table/TableRow";
import { type Column } from "../../../interfaces/Column";
import TableCell from "../Table/TableCell";
import Input from "../Input/Input";
import { AllocationContext } from "../../features/prize-pools/PrizePools/PrizePools";
import { getPercentage } from "../../../util/util";

interface Props {
  pool: Pool;
  handleDataChange: (data: any) => void;
}

const Timeline = (props: Props) => {
  const { pool, handleDataChange } = props;

  // daysArray is just as mock array with the same length as duration
  const daysArray = new Array(pool.durationInDays).fill(pool.durationInDays);

  const { totalAllocation, setTotalAllocation } = useContext(AllocationContext);

  useEffect(() => {
    const getTotalAllocation = (): number => {
      if (pool.prizeLimitType === LIMIT_DIFFERENT) {
        return pool.prizeLimitPerDay.reduce(
          (acc, current) => acc + current.prizeLimit,
          0,
        );
      } else {
        return pool.prizeLimitConstant * pool.durationInDays;
      }
    };
    setTotalAllocation(getTotalAllocation);
  }, [
    pool.durationInDays,
    pool.prizeLimitConstant,
    pool.prizeLimitType,
    pool.prizeLimitPerDay,
    totalAllocation,
  ]);

  const handleDayLimitChange = (e: any, openLimit: number) => {
    const prizeLimitPerDay = [...pool.prizeLimitPerDay];
    const limitIndex = prizeLimitPerDay.findIndex(
      (limit) => limit.onDate === openLimit,
    );
    if (limitIndex !== -1) {
      const prizeLimit = { ...prizeLimitPerDay[limitIndex] };
      prizeLimit.prizeLimit = Math.abs(Number(e.target.value));
      prizeLimitPerDay[limitIndex] = prizeLimit;
    } else {
      prizeLimitPerDay.push({
        onDate: openLimit,
        prizeLimit: Math.abs(Number(e.target.value)),
        id: Math.random(),
      });
    }
    handleDataChange({
      ...pool,
      prizeLimitPerDay,
    });
  };

  const getPrizeLimit = (day: number) => {
    const limit = pool.prizeLimitPerDay.find((limit) => limit.onDate === day);
    return limit ? limit.prizeLimit : "";
  };

  const getProbability = (day: number, field: "wins" | "perPlay") => {
    const probability = pool.winProbabilityPerDay.find(
      (probability) => probability.onDate === day,
    );
    return probability ? probability[field] : pool[field];
  };

  const handleDayProbabilityChange = (e: any, openProbability: number) => {
    const winProbabilityPerDay = [...pool.winProbabilityPerDay];
    const winIndex = winProbabilityPerDay.findIndex(
      (win) => win.onDate === openProbability,
    );
    const name = e.target.name;
    const value = Math.abs(Number(e.target.value));
    if (winIndex !== -1) {
      const winProbability = { ...winProbabilityPerDay[winIndex] };
      if (name === FIRST_VALUE) {
        winProbability.wins = value;
      } else {
        winProbability.perPlay = value;
      }
      winProbabilityPerDay[winIndex] = winProbability;
    } else {
      const wins = name === FIRST_VALUE ? value : 0;
      const perPlay = name === SECOND_VALUE ? value : 1;
      winProbabilityPerDay.push({
        id: Math.random(),
        onDate: openProbability,
        perPlay,
        wins,
      });
    }

    handleDataChange({
      ...pool,
      winProbabilityPerDay,
    });
  };

  const getLimits = (index: number) => {
    if (pool.prizeLimitType === LIMIT_DIFFERENT) {
      return (
        <span className="label-inside label-inside__prizes">
          <Input
            name={`day_${index + 1}`}
            type="number"
            value={getPrizeLimit(index + 1)}
            onChange={(e) => handleDayLimitChange(e, index + 1)}
            placeholder="0"
            min={1}
          />
          <span>prizes</span>
        </span>
      );
    }

    if (pool.prizeLimitType === LIMIT_UNLIMITED) {
      return "Unlimited";
    }

    return (
      <span className="label-inside label-inside__prizes">
        <Input
          name={`day_${index + 1}`}
          type="number"
          value={pool.prizeLimitConstant}
          disabled={true}
        />
        <span>prizes</span>
      </span>
    );
  };

  const getProbabilities = (index: number) => {
    if (pool.winProbabilityType === PROBABILITY_DIFFERENT) {
      return (
        <DoubleInput
          firstValue={getProbability(index + 1, "wins")}
          handleFirstValueChange={(e) =>
            handleDayProbabilityChange(e, index + 1)
          }
          secondValue={getProbability(index + 1, "perPlay")}
          handleSecondValueChange={(e) =>
            handleDayProbabilityChange(e, index + 1)
          }
          placeholders={["0", "0"]}
        />
      );
    }
    return (
      <div className="label-inside__plays__wrap">
        <span className="label-inside label-inside__plays">
          <Input
            name={`day_${index + 1}`}
            type="number"
            value={pool.wins}
            disabled={true}
          />
          <span>/{pool.perPlay} plays</span>
        </span>
        <p>{getPercentage(pool.wins, pool.perPlay) + "%"}</p>
      </div>
    );
  };

  const columns: Column[] = [
    {
      label: "Day",
      value: "",
      render: (data: any, columnValue: string, index: number) => (
        <span className="pr-20 middle">{`Day ${index + 1}`}</span>
      ),
    },
    {
      label: "Prize Limit",
      value: "",
      render: (data: any, columnValue: string, index: number) =>
        getLimits(index),
    },
    {
      label: "Probability (wins/plays)%",
      value: "",
      render: (data: any, columnValue: string, index: number) =>
        getProbabilities(index),
    },
    {
      label: "",
      value: "",
    },
  ];

  const renderTableCell = (row: any, column: any, index: number, i: number) => {
    return (
      <TableCell key={index + i}>
        {column.render ? (
          column.render(row, column.value, index)
        ) : (
          <span className="elipsis-200">{row[column.value]}</span>
        )}
      </TableCell>
    );
  };

  return (
    <div className="timeline">
      <Table>
        <TableHead sticky={true}>
          {columns.map(({ label }, index) => (
            <TableHeadCell key={index} heading={label} />
          ))}
        </TableHead>
        <TableBody>
          {daysArray.length > 0 ? (
            daysArray.map((row, index) => (
              <TableRow key={index + 1}>
                {columns.map((column, i) =>
                  renderTableCell(row, column, index, i),
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <td className="no-data" colSpan={columns.length}>
                No data
              </td>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default Timeline;
