import React from "react";
import "./IndividualCodes.scss";

import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import BatchUpload from "../BatchesUpload/BatchUpload";
import EmptyBox from "../../../shared/EmptyBox/EmptyBox";
import { type Batch } from "../../../../interfaces/Batch";
import Loader from "../../../shared/Loader/Loader";
import { useAppSelector } from "../../../../data/hooks";
import { STATUS_LOADING } from "../../../../constants/constants";

interface Props {
  data: any[];
  prizeId: number;
  isLoading: boolean;
  deleteBatch: (batchData: Batch, index: number) => void;
}

const IndividualCodes: React.FC<Props> = ({
  data,
  prizeId,
  deleteBatch,
}: Props) => {
  const status = useAppSelector((state: any) => state.batches.status);
  return (
    <ErrorBoundary>
      <Loader isLoading={status === STATUS_LOADING}>
        <div className="batch-container">
          {data.length > 0 ? (
            data.map((row, index) => (
              <BatchUpload
                key={`batch_${index}`}
                data={row}
                index={index}
                onDelete={deleteBatch}
                prizeId={prizeId}
              />
            ))
          ) : (
            <EmptyBox height={320} title="No batches added yet!" />
          )}
        </div>
      </Loader>
    </ErrorBoundary>
  );
};

export default IndividualCodes;
