import React from 'react';
import '../Loader/Loader.scss';
import { useAuth } from '../../../custom-hooks/useAuth';
import { AuthState } from '../../../constants/constants';

interface Props {
  // eslint-disable-next-line no-undef
  children: JSX.Element
}

const AuthLoader = (props: Props) => {
  const { authState } = useAuth();
  if (authState === AuthState.AUTH_LOADING) {
    return (
            <div className="loader-wrapper" style={{ height: '500px' }}>
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
            </div>
    );
  }

  return props.children;
};

export default AuthLoader;
