import { createSlice, type Dispatch } from "@reduxjs/toolkit";
import { type RootState } from "../store";
import { getCampaigns } from "../api/campaigns";
import { type Campaign } from "../../interfaces/Campaign";
import {
  STATUS_FAILED,
  STATUS_IDLE,
  STATUS_LOADING,
} from "../../constants/constants";

export interface CampaignsState {
  data: Campaign[];
  dataCount: number;
  status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING;
}

const initialState: CampaignsState = {
  data: [],
  status: STATUS_IDLE,
  dataCount: 0,
};

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    searchCampaignsStarted: (state) => {
      state.status = STATUS_LOADING;
    },
    searchCampaignsSuccess: (state, action) => {
      state.status = STATUS_IDLE;
      if (action.payload) {
        state.data = action.payload;
      } else {
        state.data = initialState.data;
      }
    },
    searchCampaignsError: (state, action) => {
      state.status = STATUS_FAILED;
      console.log(action.payload.code, action.payload.message);
    },
    getEmptyCampaigns: (state, action) => {
      state.status = STATUS_IDLE;
      state.data = [];
    },
  },
});

const {
  searchCampaignsStarted,
  searchCampaignsSuccess,
  searchCampaignsError,
  getEmptyCampaigns,
} = campaignsSlice.actions;

export const selectCampaigns = (state: RootState) => state.campaigns.data;
export const selectCampaignsDataCount = (state: RootState) =>
  state.campaigns.dataCount;
export const selectCampaignsStatus = (state: RootState) =>
  state.campaigns.status;

export default campaignsSlice.reducer;

export const fetchCampaigns = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(searchCampaignsStarted());
      const result = await getCampaigns(data);
      dispatch(searchCampaignsSuccess(result));
    } catch (error) {
      dispatch(searchCampaignsError(error));
    }
  };
};

export const emptyCampaigns = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmptyCampaigns([]));
  };
};
