import React, { useEffect, useState } from "react";
import "./BarCode.scss";

import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import { barcodeType } from "../../../../constants/constants";

interface Props {
  code: any;
}

const BarCode: React.FC<Props> = ({ code }: Props) => {
  const [isValid, setIsValid] = useState(true);

  const checksum = (number: string) => {
    const res = number
      .substr(0, 12)
      .split("")
      .map((n: string) => +n)
      .reduce(
        (sum: number, a: number, idx: number) =>
          idx % 2 ? sum + a * 3 : sum + a,
        0,
      );

    return (10 - (res % 10)) % 10;
  };

  useEffect(() => {
    if (code.type === barcodeType.EAN13) {
      const isValidInput =
        code.name.search(/^[0-9]{13}$/) !== -1 &&
        +code.name[12] === checksum(code.name);
      setIsValid(isValidInput);
    }
  }, [code]);

  const getCodeFormat = () => {
    switch (code.type) {
      case barcodeType.EAN128:
        return "CODE128";
      case barcodeType.EAN13:
        return "EAN13";
    }
  };

  return (
    <ErrorBoundary>
      <div className="barcode">
        {!isValid && (
          <p className="invalid-barcode">
            Invalid input for this barcode type.
          </p>
        )}

        {isValid && code.type === barcodeType.QR && (
          <QRCode value={code.name} style={{width: "40%"}}/>
        )}

        {isValid && code.type === barcodeType.TEXT && (
          <div className="barcode__text">{code.name}</div>
        )}

        {isValid && (code.type === barcodeType.EAN128 || code.type === barcodeType.EAN13) && (
          <Barcode value={code.name} format={getCodeFormat()} />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default BarCode;
