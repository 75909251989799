import React from "react";

import UploadImage from "../../../shared/UploadImage/UploadImage";
import Input from "../../../shared/Input/Input";
import TextArea from "../../../shared/TextArea/TextArea";
import "./PoolDetails.scss";
import Dropdown from "../../../shared/Dropdown/Dropdown";
import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import { isValidJsonString, toBase64} from "../../../../util/util";
import { type Pool } from "../../../../interfaces/Pool";
import { ReactComponent as Info } from "../../../../assets/icons/info.svg";
import {
  POOL_ACTIVE,
  POOL_DEFAULT,
  POOL_DRAFT,
  POOL_INACTIVE,
} from "../../../../constants/constants";
import { modal } from "../../../../data/slices/notificationSlice";
import { useAppDispatch } from "../../../../data/hooks";

const options = [
  {
    id: POOL_INACTIVE,
    label: "Inactive",
  },
  {
    id: POOL_ACTIVE,
    label: "Active",
  },
  {
    id: POOL_DRAFT,
    label: "Draft",
  },
  {
    id: POOL_DEFAULT,
    label: "Default",
  },
];

interface Props {
  pool: Pool;
  handleDataChange: (pool: Pool) => void;
  errors: any;
  save: () => void;
}

const PoolDetails = ({ pool, handleDataChange, errors, save }: Props) => {
  const dispatch = useAppDispatch();

  const handleImageChange = (event: any) => {
    if (!event) {
      return;
    }
    const file = event.target.files[0];

    if (file.size > 3000000) {
      dispatch(
        modal.notify({
          message: "File size must be less then 3MB.",
          type: "error",
        }),
      );
    }

    toBase64(file)
      .then((result) => {
        const image = JSON.stringify({
          filename: event.target.files[0].name,
          base64: result,
        });
        const fileName = file.name;
        handleDataChange({
          ...pool,
          image,
          imageFilename: fileName,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputChange = (event: any) => {
    let value = event.target.value;
    const name = event.target.name;

   if (name === "durationInDays") {
      value = Math.min(Math.abs(Number(event.target.value)), 92);
    }

    handleDataChange({
      ...pool,
      [name]: value
    });
  };

  const handleStatusChange = (e: any) => {
    handleDataChange({
      ...pool,
      status: e.id,
    });
  };

  const getSelectedStatus = () => {
    return options.filter((option) => option.id === pool.status)[0];
  };

  const parseImage = (image: string | null) => {
    if (image && isValidJsonString(image)) {
      return JSON.parse(image).base64;
    }
    return image ?? "";
  };

  const savePool = () => {
    if (!pool.id && pool.title) {
      save();
    }
  };

  return (
    <ErrorBoundary>
      <div className="pool-details">
        <div className="pool-details__top">
          <p>
            New prize pool can have multiple prizes and can be defined as prize
            group for specific outcome in the game.
          </p>
          <div className="pool-details__top__input">
            <div className="input-group">
              <label>
                Pool name<span className="required">*</span>
              </label>
              <Input
                placeholder="Title"
                name="title"
                type="text"
                autoFocus={true}
                onFocusOut={savePool}
                onChange={handleInputChange}
                value={pool.title || ""}
                errors={errors.title}
              />
              {errors.title ? (
                <div className="error-message">Pool name is a required field.</div>
              ) : (
                ""
              )}
            </div>
            <div className="input-group">
              <label>Internal Pool ID</label>
              <Input
                placeholder="ID"
                name="internalPoolId"
                type="text"
                onChange={handleInputChange}
                value={pool.internalPoolId ? pool.internalPoolId : ""}
              />
            </div>
            <div className="info">
              <Info />
              <p>Fill out the pool name so you can move to the next tab.</p>
            </div>
          </div>
          <div className="pool-details__top__description">
            <label>Pool description</label>
            <TextArea
              placeholder="Type here..."
              name="description"
              onChange={handleInputChange}
              value={pool.description}
              customClass={"in-pools"}
            />
          </div>
          <div className="pool-details__top__bottom-input">
            <div className="input-group">
              <label>Status</label>
              <Dropdown
                data={options}
                handleChange={handleStatusChange}
                selectedValue={getSelectedStatus()}
                width={360}
              />
            </div>
            <div className="input-group duration">
              <label>Duration</label>
              <span>
                <Input
                  placeholder="0"
                  name="durationInDays"
                  type="number"
                  onChange={handleInputChange}
                  value={pool.durationInDays}
                  min="0"
                  max="92"
                  errors={errors.durationInDays}
                />
              </span>
              <div className="info">
                <Info />
                <p>Maximum pool duration is 92 days.</p>
              </div>
              {errors.durationInDays ? (
                <div className="error-message">
                  Duration is a required field.
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="pool-details__bottom">
          <UploadImage
            text="Image"
            name="image"
            height={210}
            onChange={handleImageChange}
            preview={parseImage(pool.image)}
            title={pool.imageFilename}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default PoolDetails;
