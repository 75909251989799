import React, {Fragment, useState} from 'react';

import Prizes from '../../components/features/prizes/Prizes/Prizes';
import PrizePools from '../../components/features/prize-pools/PrizePools/PrizePools';

import './Home.scss';
import Navbar from '../../components/shared/Navbar/Navbar';
import Notification from '../../components/shared/Notification/Notification';

const Home = () => {
  const [activeView, setActiveView] = useState('prizes');

  return (
    <Fragment>
      <Navbar></Navbar>
      <Notification/>

      <div className="container">
        {activeView === 'prizes' ? <Prizes setView={setActiveView}/> : <PrizePools setView={setActiveView}/>}
      </div>
    </Fragment>
  );
};

export default Home;
