import React, { type ChangeEventHandler, type MouseEventHandler } from "react";
import "./FileUpload.scss";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload-small.svg";
import { ReactComponent as CsvIcon } from "../../../assets/icons/csv.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

interface Props {
  filename: string;
  fileSize?: number | null;
  error: boolean;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  clearFile?: MouseEventHandler<SVGSVGElement>;
  showSize?: boolean | undefined;
}

const FileUpload = ({
  filename,
  fileSize,
  error,
  handleChange,
  clearFile,
  showSize,
}: Props) => {
  return (
    <div className="file-upload">
      {filename ? (
        <label className="file">
          <CsvIcon className="csv_icon" />
          <p>{filename}</p>
          <CloseIcon className="close_icon" onClick={clearFile} />
        </label>
      ) : (
        <label>
          <input
            className="upload-btn"
            data-testid="upload-btn"
            type="file"
            multiple
            accept=".csv"
            onChange={handleChange}
          />
          <UploadIcon />
          {"Upload"}
        </label>
      )}
      {showSize ? (
        <p>
          {fileSize
            ? `${(fileSize / (1024 * 1024)).toFixed(2)} MB`
            : "Maximum upload size is 3MB."}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default FileUpload;
