import React, { useState } from "react";

import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Loader from "../../../shared/Loader/Loader";
import Table from "../../../shared/Table/Table";
import TableBody from "../../../shared/Table/TableBody";
import TableHead from "../../../shared/Table/TableHead";
import TableHeadCell from "../../../shared/Table/TableHeadCell";
import TableRow from "../../../shared/Table/TableRow";
import TableCell from "../../../shared/Table/TableCell";
import Pagination from "../../../shared/Pagination/Pagination";
import { type Column } from "../../../../interfaces/Column";

import { ReactComponent as Edit } from "../../../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../../../assets/icons/delete.svg";
import { formatDateTime, getSortBy, stripHtmlTags } from "../../../../util/util";
import EmptyBox from "../../../shared/EmptyBox/EmptyBox";

interface Props {
  data: any[];
  totalCount: number;
  isLoading: boolean;
  onPageChange: (page: string | number) => void;
  currentPage: number;
  editPrize: (id: number) => void;
  deletePrize: (id: number) => void;
  handleSort: (sortBy: string, order: string) => void;
}

const PrizesTable: React.FC<Props> = ({
  data,
  isLoading,
  totalCount,
  onPageChange,
  currentPage,
  editPrize,
  deletePrize,
  handleSort,
}: Props) => {
  const [sort, setSort] = useState({
    by: "createdAt",
    order: "DESC",
  });

  const changeSorting = (by: string, order: string) => {
      setSort({
          by,
          order,
      });
      handleSort(getSortBy(by), order);
  };

  const columns: Column[] = [
    {
        label: "ID",
        value: "internalPrizeId",
        sorting: true,
    },
    {
      label: "Image",
      value: "gameImage",
      render: (data: any, columnValue: string) =>
        data[columnValue] ? (
          <img src={data[columnValue]} alt="Prize" />
        ) : (
          <img
            src={require("./../../../../assets/images/white.png")}
            alt="Prize"
          />
        ),
    },
    {
        label: "Title",
        value: "title",
        render: (data: any) => (
            <span className="cursor-pointer" onClick={() => editPrize(data.id)}>{data.title}</span>
        ),
        sorting: true,
    },
    {
      label: "Description",
      value: "description",
      render: (data: any, columnValue) => (
        <span className="elipsis-200" title={stripHtmlTags(data[columnValue])}>
          {stripHtmlTags(data[columnValue])}
        </span>
      ),
        sorting: true,
    },
    {
      label: "Amount",
      value: "amount",
      sorting: true,
    },
    {
      label: "Reserved",
      value: "reserved",
    },
    {
      label: "Preallocated",
      value: "preAllocated",
    },
    {
      label: "Redeemed",
      value: "redeemed",
    },
    {
      label: "Avaliable",
      value: "available",
    },
    {
      label: "Created at",
      value: "createdAt",
      sorting: true,
      render: (data: any, columnValue) => formatDateTime(data[columnValue]),
    },
    {
      label: "Included in pool",
      value: "pools",
      render: (data: any, columnValue: string) => {
        const pools = data[columnValue].map((pool: any) => {
          return pool.title;
        });

        return <span className="elipsis-140">{pools.join(", ")}</span>;
      },
    },
    {
      label: "Actions",
      value: "actions",
      render: (data: any) => (
        <div className="actions">
          <Delete
            className="cursor-pointer"
            onClick={() => deletePrize(data.id)}
            title="Delete"
          />
          <Edit
            className="cursor-pointer"
            onClick={() => editPrize(data.id)}
            title="Edit"
          />
        </div>
      ),
    },
  ];

  const renderTableCell = (row: any, column: any, index: number) => {
    return (
      <TableCell key={index}>
        {column.render ? (
          column.render(row, column.value)
        ) : (
          <span className="elipsis-200">{row[column.value]}</span>
        )}
      </TableCell>
    );
  };

  return (
    <ErrorBoundary>
      <Loader isLoading={isLoading}>
        <>
          <Table>
            <TableHead>
              {columns.map(({ label, sorting, value }, index) => (
                <TableHeadCell
                  key={index}
                  heading={label}
                  columnName={value}
                  sorting={sorting}
                  sortBy={sort.by}
                  order={sort.order}
                  setSort={changeSorting}
                />
              ))}
            </TableHead>
            <TableBody>
              {data.length > 0
                ? data.map((row, index) => (
                    <TableRow key={index}>
                      {columns.map((column, i) =>
                        renderTableCell(row, column, i),
                      )}
                    </TableRow>
                  ))
                : ""}
            </TableBody>
            <Pagination
              colSpan={columns.length}
              totalCount={totalCount}
              showPerPage={10}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          </Table>
          {data.length > 0 ? (
            ""
          ) : (
            <EmptyBox
              height={520}
              title="No prizes added yet!"
              description="Add prizes that your customers can win while playing games and keep an overview of available prizes.
                                  You can add prize for first place, game won or game lost or define at which rate prizes are won by customers."
            />
          )}
        </>
      </Loader>
    </ErrorBoundary>
  );
};

export default PrizesTable;
