import { createSlice, type Dispatch } from '@reduxjs/toolkit';
import { type RootState } from '../store';
import { resendPrizeCode } from '../api/winners';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING } from '../../constants/constants';

export interface ResendPrizeState {
  data: string
  status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING
}

const initialState: ResendPrizeState = {
  data: '',
  status: STATUS_IDLE
};

const resendPrizeSlice = createSlice({
  name: 'resendPrize',
  initialState,
  reducers: {
    resendPrizeStarted: (state, action) => {
      state.status = STATUS_LOADING;
      state.data = action.payload.uuid;
    },
    resendPrizeSuccess: (state, action) => {
      state.status = STATUS_IDLE;
      state.data = initialState.data;
    },
    resendPrizeError: (state, action) => {
      state.status = STATUS_FAILED;
      console.log(action.payload.code, action.payload.message);
    }
  }
});

const { resendPrizeStarted, resendPrizeSuccess, resendPrizeError } = resendPrizeSlice.actions;

export const selectResendPrize = (state: RootState) => state.resendPrize.data;
export const selectStatus = (state: RootState) => state.resendPrize.status;

export default resendPrizeSlice.reducer;

export const resendPrize = (data: { uuid: string, campaignId: string }) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(resendPrizeStarted({ uuid: data.uuid }));
      const result = await resendPrizeCode(data);
      dispatch(resendPrizeSuccess(result.data));
    } catch (error) {
      dispatch(resendPrizeError(error));
    }
  };
};
