import React from "react";
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { parseJwt } from "../util/util";
import tokenService from "../auth/TokenService";
import { AuthState } from "../constants/constants";

interface UseAuthContext {
  token: string | null;
  authState: string;
  user: {
    companyId: string | undefined;
  };
}

const AuthCtx = createContext<UseAuthContext | null>(null);

export function AuthProvider(props: PropsWithChildren) {
  const accessToken = tokenService.getCookieAccessToken();
  const [companyId, setCompanyId] = useState("");
  const [tokenCompanyId, setTokenCompanyId] = useState("");
  const [token] = useState(accessToken);
  const [authState, setAuthState] = useState("");

  const getCompanyId = useCallback(() => {
    const ctx = localStorage.getItem("brame-context");
    const companyViewId = localStorage.getItem("companyViewId");
    if (!ctx) return null;
    const parsed = JSON.parse(ctx);
    if (companyViewId) {
      return JSON.parse(companyViewId);
    }
    return parsed.company.id;
  }, []);

  useEffect(() => {
    setAuthState(AuthState.AUTH_LOADING);
    if (token) {
      const parsedToken = parseJwt(token);
      setTokenCompanyId(parsedToken.company_id);
      const cid = getCompanyId() ?? tokenCompanyId;
      setCompanyId(cid);
      if (cid) {
        setAuthState(AuthState.AUTH_LOGGED_IN);
      }
    }
  }, [token, getCompanyId, setCompanyId, tokenCompanyId]);

  const value = {
    token,
    authState,
    user: {
      companyId,
    },
  };

  return <AuthCtx.Provider value={value} {...props} />;
}

export function useAuth() {
  const ctx = useContext(AuthCtx);

  if (!ctx) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return ctx;
}
