import React, { useEffect, useState } from 'react';
import './CodesList.scss';
import { ReactComponent as Eye } from '../../../../assets/icons/eye.svg';

import ErrorBoundary from '../../../shared/ErrorBoundary/ErrorBoundary';
import Loader from '../../../shared/Loader/Loader';
import TableHeadCell from '../../../shared/Table/TableHeadCell';
import { type Column } from '../../../../interfaces/Column';
import { useAppDispatch, useAppSelector } from '../../../../data/hooks';
import { fetchCodes } from '../../../../data/slices/codesSlice';
import Modal from '../../../shared/Modal/Modal';
import Button from '../../../shared/Button/Button';
import BarCode from '../BarCode/BarCode';
import Search from '../../../shared/Search/Search';
import {STATUS_LOADING, barcodeType} from '../../../../constants/constants';

interface Props {
  batchId: number,
  codeType: string
}

const resolveTitle = (codeType: string) => {
  switch (codeType) {
    case barcodeType.QR:
      return "QR code preview";
    case barcodeType.TEXT:
      return "Code preview";
    default:
      return "Barcode preview";
  }
};

const CodesList: React.FC<Props> = ({ batchId, codeType }: Props) => {
  const [isOpenBarCodeModal, setIsOpenBarCodeModal] = useState(false);
  const [code, setCode] = useState({
    name: "",
    type: codeType,
  });  const data = useAppSelector((state: any) => state.codes.data);
  const status = useAppSelector((state: any) => state.codes.status);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCodes(batchId, ''));
  }, [dispatch, batchId]);

  const columns: Column[] = [
    {
      label: 'Code ID',
      value: 'name'
    },
    {
      label: '',
      value: 'edit'
    }
  ];

  const toggleBarCodeModal = (codeList?: any) => {
    console.log(codeList);
    if (codeList) {
      setCode({...code, name: codeList.name});
    }
    setIsOpenBarCodeModal(!isOpenBarCodeModal);
  };

  const search = (searchTerm: string) => {
    dispatch(fetchCodes(batchId, searchTerm));
  };

  return (
    <div className="codes-container">
      <div className="search-header">
        <Search search={search} />
      </div>
      <ErrorBoundary>
        <div className="codes-list">
          <div className="codes-list__header">
            {columns.map(({ label }, index) => (
              <TableHeadCell key={index} heading={label} />
            ))}
          </div>
          <Loader isLoading={status === STATUS_LOADING}>
            <div className="codes-list__body">
              {data.map((row: any, index: number) => (
                <div key={index} className="codes-list__row">
                  <div className="codes-list__name">{row.name}</div>
                  <div
                    className="codes-list__link"
                    onClick={() => {
                      toggleBarCodeModal(row);
                    }}
                  >
                    <Eye />
                  </div>
                </div>
              ))}
            </div>
          </Loader>
        </div>

        <Modal
          isOpen={isOpenBarCodeModal}
          setIsOpen={setIsOpenBarCodeModal}
          title={resolveTitle(codeType)}
          customClass="barcode-modal"
        >
          <BarCode code={code} />
          <div className="modal__footer">
            <div className="modal__footer--btns">
              <Button
                type="primary"
                customClass="btn-small"
                onClick={() => {
                  toggleBarCodeModal();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </ErrorBoundary>
    </div>
  );
};

export default CodesList;
