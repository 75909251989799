import React, { useState } from 'react';

import Input from '../../../shared/Input/Input';
import TextArea from '../../../shared/TextArea/TextArea';
import { ReactComponent as Info } from '../../../../assets/icons/info.svg';
import { ReactComponent as Eye } from '../../../../assets/icons/eye.svg';
import './PrizeDetails.scss';
import { isValidJsonString, toBase64} from '../../../../util/util';
import UploadImage from '../../../shared/UploadImage/UploadImage';
import { type Prize } from '../../../../interfaces/Prize';
import Modal from '../../../shared/Modal/Modal';
import Button from '../../../shared/Button/Button';
import {modal} from "../../../../data/slices/notificationSlice";
import {useAppDispatch} from "../../../../data/hooks";

interface Props {
  data: any
  handleOnChange: (prize: Prize) => void
  errors: any
  save: () => void
}

const PrizeDetails = ({ data, handleOnChange, errors, save }: Props) => {
  const [isHtmlPreviewOpen, setIsHtmlPreviewOpen] = useState<{
    open: boolean
    type: string
  }>({
    open: false,
    type: 'description'
  });
  const dispatch = useAppDispatch();

  const handleImageChange = (event: any, imageType?: string | null) => {
    if (!event && imageType) {
      handleOnChange({
        ...data,
        [imageType]: ''
      });
      return;
    }

    if (event.target.files[0].size > 3000000) {
      dispatch(modal.notify({
        message: 'File size must be less then 3MB.',
        type: 'error'
      }));
    }

    const file = event.target.files[0];
    const name = event.target.name;
    toBase64(file)
      .then((result) => {
        const image = JSON.stringify({
          filename: file.name,
          base64: result
        });
        const filenameProp = `${name}Filename`;
        const fileName = file.name;
        handleOnChange({
          ...data,
          [name]: image,
          [filenameProp]: fileName
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTextChange = (event: any) => {
    let value = event.target.value;
    const name = event.target.name;

   if (name === 'daysValid') {
      value = Math.abs(Number(value));
    }

    handleOnChange({
      ...data,
      [name]: value
    });
  };

  const parseImage = (image: string) => {
    if (image && isValidJsonString(image)) {
      return JSON.parse(image).base64;
    }
    return image ?? '';
  };

  const savePrize = () => {
    if (!data.id && data.title) {
      save();
    }
  };

  return (
    <div className="prize-details">
      <div className="prize-details__top">
        <p>
          The details of a prize refer to the specific elements that make up the
          reward, such as prize name, description, ID, Validity and images.
        </p>
        <div className="input-group">
          <label>
            Title<span className="required">*</span>
          </label>
          <Input
            placeholder="Title"
            name="title"
            type="text"
            autoFocus={true}
            onFocusOut={savePrize}
            onChange={handleTextChange}
            value={data.title || ""}
            errors={errors.title}
          />
          {errors.title ? (
            <div className="error-message">Title is a required field.</div>
          ) : (
            ""
          )}
        </div>
        <div className="input-group input-group__small">
          <label>Internal prize ID</label>
          <Input
            placeholder="ID"
            name="internalPrizeId"
            type="text"
            onChange={handleTextChange}
            value={data.internalPrizeId || ""}
          />
        </div>
        <div className="input-group input-group__small">
          <label>Validity</label>
          <span className="label-inside label-inside__days">
            <Input
              placeholder="0"
              name="daysValid"
              type="number"
              onChange={handleTextChange}
              value={data.daysValid || 0}
              min="0"
              max="10"
              errors={errors.daysValid}
            />
          </span>
          {errors.daysValid ? (
            <div className="error-message">
              Prize validity is a required field.
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="info">
          <Info />
          <p>Fill out the prize name so you can move to the next tab.</p>
        </div>
        <div className="input-group">
          <label>Prize description</label>
          <TextArea
            placeholder="Type here..."
            name="description"
            onChange={handleTextChange}
            value={data.description || ""}
            customClass={"in-prizes"}
          />
          <span
            className="preview-html"
            onClick={() =>
              setIsHtmlPreviewOpen({ open: true, type: "description" })
            }
          >
            <Eye /> HTML preview
          </span>
        </div>
        <div className="input-group">
          <label>Optional text</label>
          <TextArea
            placeholder="Type here..."
            name="legalNotes"
            onChange={handleTextChange}
            value={data.legalNotes || ""}
            customClass={"in-prizes"}
          />
          <span
            className="preview-html"
            onClick={() =>
              setIsHtmlPreviewOpen({ open: true, type: "legalNotes" })
            }
          >
            <Eye /> HTML preview
          </span>
        </div>
      </div>

      <div className="prize-details__bottom">
        <UploadImage
          name="resultsImage"
          text={"Result image"}
          onChange={handleImageChange}
          preview={parseImage(data.resultsImage)}
          title={data.resultsImageFilename}
        />
        <UploadImage
          name="gameImage"
          text={"Game item image"}
          onChange={handleImageChange}
          preview={parseImage(data.gameImage)}
          title={data.gameImageFilename}
        />
      </div>

      <Modal
        isOpen={isHtmlPreviewOpen.open}
        setIsOpen={() =>
          setIsHtmlPreviewOpen({
            open: false,
            type: "",
          })
        }
        title="HTML preview"
        customClass="html-preview-modal"
      >
        <div className="html-preview-block">
          <div
            dangerouslySetInnerHTML={{ __html: data[isHtmlPreviewOpen.type] }}
          ></div>
        </div>
        <div className="modal__footer">
          <div className="modal__footer--btns">
            <Button
              type="secondary"
              customClass="btn-small"
              onClick={() =>
                setIsHtmlPreviewOpen({
                  open: false,
                  type: "",
                })
              }
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PrizeDetails;
