import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "./CommonCodeBatch.scss";

import { barcodeType } from "../../../../constants/constants";
import { type CommonCodeBatch } from "../../../../interfaces/Batch";
import Dropdown from "../../../shared/Dropdown/Dropdown";
import Input from "../../../shared/Input/Input";
import Button from "../../../shared/Button/Button";
import { ReactComponent as Eye } from "../../../../assets/icons/eye.svg";
import Modal from "../../../shared/Modal/Modal";
import BarCode from "../BarCode/BarCode";
import EmptyBox from "../../../shared/EmptyBox/EmptyBox";

type BarcodeType = (typeof barcodeType)[keyof typeof barcodeType];
type DataChangedCb = (data: Partial<CommonCodeBatch>) => void;

interface CommonCodeBatchComponentProps {
  batch: CommonCodeBatch;
  onDataChange: DataChangedCb;
  errors: { barcode: boolean; amount: boolean; validity: boolean };
}

const formLabels = {
  BARCODE_TYPE: "Code Type",
  CODES_AMOUNT: "Codes Amount",
  EXPIRATION: "Code Expiration",
  VALIDITY: "Code Validity",
};

const barcodeTypeOptions = (
  Object.keys(barcodeType) as Array<keyof typeof barcodeType>
).map((type) => ({
  id: barcodeType[type],
  label: type as string,
}));

const CommonCodeBatchComponent: React.FC<CommonCodeBatchComponentProps> = ({
  batch,
  onDataChange,
  errors,
}) => {
  const [isOpenBarCodeModal, setIsOpenBarCodeModal] = useState(false);
  const [code, setCode] = useState({
    name: "",
    type: "",
  });
  const [commonCodeErrors, setCommonCodeErrors] = useState({
    barcode: false,
    amount: false,
    validity: false,
  });

  useEffect(() => {
    setCode({
      name: batch.commonCode,
      type: batch.codeType,
    });
  }, [batch]);

  useEffect(() => {
    setCommonCodeErrors(errors);
  }, [errors]);

  const onBarcodeTypeChange: (
    type: BarcodeType,
    onDataChange: DataChangedCb,
  ) => void = (type, onDataChange) => {
    setCode({
      ...code,
      type,
    });
    onDataChange({ codeType: type });
  };

  const onCommonCodeChange: (
    commonCode: string,
    onDataChange: DataChangedCb,
  ) => void = (commonCode, onDataChange) => {
    setCode({
      ...code,
      name: commonCode,
    });
    onDataChange({ commonCode });
    setCommonCodeErrors({
      ...errors,
      barcode: false,
    });
  };

  const onCodesAmountChange: (
    amount: number,
    onDataChange: DataChangedCb,
  ) => void = (amount, onDataChange) => {
    onDataChange({ codesAmount: amount });
    setCommonCodeErrors({
      ...errors,
      amount: false,
    });
  };

  const onExpirationDateChange: (
    date: Date | null,
    onDataChange: DataChangedCb,
  ) => void = (date, onDataChange) => {
    if (date) {
      onDataChange({ expiration: date.toISOString() });
    }
  };

  const onCodeValidityChange: (
    validity: number,
    onDataChange: DataChangedCb,
  ) => void = (validity, onDataChange) => {
    const value = Math.min(Math.abs(Number(validity)), 1000000);
    if (Number.isInteger(value) && value > 0) {
      onDataChange({ codeValidity: value });
    }
    setCommonCodeErrors({
      ...errors,
      validity: false,
    });
  };

  const viewCode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (code.name.length !== 13 && code.type === barcodeType.EAN13) {
      setCommonCodeErrors({
        ...errors,
        barcode: true,
      });
    } else {
      setIsOpenBarCodeModal(true);
    }
  };

  return (
    <>
      {batch ? (
        <form
          className="common-code-batch"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="common-code-batch__row">
            <div className="common-code-batch__form-control common-code-batch__form-control--barcode">
              <label className="form-control__label">Barcode</label>
              <Input
                name="codeName"
                type="string"
                placeholder={
                  batch.codeType === barcodeType.EAN13
                    ? "Your barcode should have 13 digits"
                    : ""
                }
                value={batch.commonCode}
                onChange={({ target: { value } }) => {
                  onCommonCodeChange(value, onDataChange);
                }}
                errors={
                  commonCodeErrors.barcode && code.type === barcodeType.EAN13
                }
              />
              {commonCodeErrors.barcode && code.type === barcodeType.EAN13 ? (
                <div className="error-message">
                  Barcode must be 13 characters long.
                </div>
              ) : (
                ""
              )}
            </div>
            <Button
              customClass={
                commonCodeErrors.barcode && code.type === barcodeType.EAN13
                  ? "with-errors"
                  : ""
              }
              type="secondary"
              onClick={(e) => viewCode(e)}
            >
              <Eye /> View code
            </Button>
          </div>
          <div className="common-code-batch__row bottom">
            <div className="common-code-batch__form-control common-code-batch__form-control--barcode-type">
              <label className="form-control__label">
                {formLabels.BARCODE_TYPE}
              </label>
              <Dropdown
                data={barcodeTypeOptions}
                selectedValue={
                  barcodeTypeOptions.find((o) => o.id === batch.codeType) as {
                    id: string;
                    label: string;
                  }
                }
                handleChange={(option) => {
                  onBarcodeTypeChange(option.id as BarcodeType, onDataChange);
                }}
              />
            </div>
            <div className="common-code-batch__form-control common-code-batch__form-control--expiration">
              <label className="form-control__label">
                {formLabels.EXPIRATION}
              </label>
              <DatePicker
                selected={new Date(batch.expiration)}
                dateFormat="dd/MM/yyyy"
                minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                onChange={(date) => {
                  onExpirationDateChange(date, onDataChange);
                }}
              />
            </div>
            <div className="common-code-batch__form-control common-code-batch__form-control--codes-amount">
              <label className="form-control__label">
                {formLabels.CODES_AMOUNT}
              </label>
              <Input
                name="codesAmount"
                type="number"
                min={1}
                max={100000}
                value={batch.codesAmount}
                onChange={({ target: { value } }) => {
                  onCodesAmountChange(+value, onDataChange);
                }}
                errors={commonCodeErrors.amount}
              />
              {commonCodeErrors.amount ? (
                <div className="error-message">
                  Must be equal or less then 100000
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="common-code-batch__form-control common-code-batch__form-control--validity">
              <label className="form-control__label">
                {formLabels.VALIDITY}
              </label>
              <span>
                <Input
                  name="codeValidity"
                  type="number"
                  min={1}
                  max={1000000}
                  value={batch.codeValidity}
                  onChange={({ target: { value } }) => {
                    onCodeValidityChange(+value, onDataChange);
                  }}
                  errors={commonCodeErrors.validity}
                />
                {commonCodeErrors.validity ? (
                  <div className="error-message">
                    Must be equal or less then 1000000
                  </div>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>

          <Modal
            isOpen={isOpenBarCodeModal}
            setIsOpen={setIsOpenBarCodeModal}
            title={`${code.type === "QR" ? "QR code" : "Barcode"} preview`}
            customClass="barcode-modal"
          >
            <BarCode code={code} />
            <div className="modal__footer">
              <div className="modal__footer--btns">
                <Button
                  type="secondary"
                  customClass="btn-small"
                  onClick={() => {
                    setIsOpenBarCodeModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
        </form>
      ) : (
        <EmptyBox height={320} title="No batches added yet!" />
      )}
    </>
  );
};

export default CommonCodeBatchComponent;
