import React, { useState } from 'react';

import './Search.scss';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as ClearIcon } from '../../../assets/icons/close.svg';

interface Props {
  search: (searchTerm: string) => void
  placeholder?: string
  size?: 'large' | 'medium'
}

const Search = ({ search, placeholder, size }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const searchData = (e: any) => {
    if (e.key === 'Enter') {
      search(searchTerm);
    }
  };

  const clear = () => {
    setSearchTerm('');
    search('');
  };

  const searchDataOnClick = () => {
    search(searchTerm);
  };

  return (
    <div className="search">
      <input
        className={`${size ?? ''}`}
        type="text"
        placeholder={placeholder ?? 'Search'}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        onKeyPress={searchData}
      />

      {searchTerm.length
        ? (
        <ClearIcon
          onClick={() => {
            clear();
          }}
        />
          )
        : (
        <SearchIcon
          onClick={() => {
            searchDataOnClick();
          }}
        />
          )}
    </div>
  );
};

export default Search;
