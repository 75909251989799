import React, { Component, type ErrorInfo } from 'react';

import './ErrorBoundary.scss';

interface Props {
  children: React.ReactNode
}

interface State {
  error: Error | null
  errorInfo: ErrorInfo | null
}

class ErrorBoundary extends Component<Props, State> {
  constructor (props: Props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch (error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    // console.log(error, errorInfo);
    this.setState({
      error,
      errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  render () {
    if (this.state.errorInfo) {
      // Error path
      return (
                <div className="alert-error">
                    <h3>Something went wrong, please refresh page and try again.</h3>
                </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
