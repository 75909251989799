import React, {useEffect, useState} from 'react';
import Search from '../../components/shared/Search/Search';
import './WinnerSearch.scss';
import WinnerSearchTable from '../../components/features/winner-search/WInnerSearchTable/WinnerSearchTable';
import {useAppDispatch, useAppSelector} from '../../data/hooks';
import {fetchWinners} from '../../data/slices/winnersSlice';
import {useParams} from 'react-router-dom';
import {fetchPoolsByCampaignid} from '../../data/slices/poolsSlice';
import {type Winner} from '../../interfaces/Winner';
import {type Pool} from '../../interfaces/Pool';
import {STATUS_LOADING} from '../../constants/constants';
import {type Win} from '../../interfaces/Win';
import {createWin} from '../../data/slices/generateWinSlice';
import {type RootState} from '../../data/store';
import Notification from "../../components/shared/Notification/Notification";

const WinnerSearch = () => {
  const {campaignId} = useParams();
  const initialRequestData = {
    searchTerm: '',
    campaignId,
    perPage: 8,
    pageNumber: 0,
    sortBy: 'play_registration_date',
    order: 'DESC'
  };
  const [requestData, changeRequestData] = useState(initialRequestData);
  const winners = useAppSelector((state: any) => state.winners.data);
  const pools = useAppSelector((state: any) => state.pools.data);
  const totalCount = useAppSelector((state: any) => state.winners.dataCount);
  const status = useAppSelector((state: any) => state.winners.status);
  const createWinStatus = useAppSelector((state: any) => state.win.status);
  const campaignTimeZoneOffset = useAppSelector(
    (state: RootState) => state.winners.campaignTimeZoneOffset
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchWinners(requestData)).then(() => {
      if (campaignId) {
        dispatch(fetchPoolsByCampaignid(campaignId));
      }
    });
  }, [campaignId, dispatch, requestData]);

  const prepareData = () => {
    if (winners && pools) {
      return winners.map((winner: Winner) => {
        if (winner.poolId) {
          const winnerPools = pools.filter(
            (pool: Pool) => winner.poolId === pool.id
          );
          return {
            ...winner,
            poolStatus: winnerPools.length ? winnerPools[0].status : 0
          };
        }
        return {
          ...winner,
          poolStatus: 0
        };
      });
    }

    return winners;
  };

  const search = (searchTerm: string) => {
    changeRequestData({
      ...requestData,
      searchTerm
    });
  };

  const onPageChange = (pageNumber: string | number) => {
    const page = pageNumber !== 0 ? +pageNumber - 1 : pageNumber;
    changeRequestData({
      ...requestData,
      pageNumber: page
    });
  };

  const generateNewWin = (
    email: string | undefined,
    prizeId: string | number,
    visitorId: string | null,
    reason: string,
    language: string,
    languageUUID: string
  ) => {
    if (campaignId) {
      const win: Win = {
        firstName: '',
        lastName: '',
        email,
        campaignId,
        prizeId,
        visitorId,
        language,
        languageUUID,
        message: reason
      };

      dispatch(createWin(win)).then(() => {
        dispatch(fetchWinners(requestData)).then(() => {
          dispatch(fetchPoolsByCampaignid(campaignId));
        });
      });
    }
  };

  return (
    <div className="container-lg">
      <Notification/>
      <div className="winner-search">
        <h2>Check for prize by entering e-mail</h2>
        <div className="winner-search__container">
          <div className="winner-search__header">
            <p>Search winners</p>
            <Search search={search} placeholder="Enter an email" size="large"/>
          </div>

          <div className="winner-search__body">
            <WinnerSearchTable
              data={prepareData()}
              isLoading={
                status === STATUS_LOADING || createWinStatus === STATUS_LOADING
              }
              totalCount={totalCount}
              onPageChange={onPageChange}
              currentPage={requestData.pageNumber + 1}
              campaignId={campaignId}
              generateWin={generateNewWin}
              campaignTimeZoneOffset={campaignTimeZoneOffset}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinnerSearch;
