import React, { type ChangeEventHandler } from 'react';

import './DoubleInput.scss';
import { getPercentage } from '../../../util/util';

interface Props {
  firstValue: string | number
  handleFirstValueChange: ChangeEventHandler<HTMLInputElement>
  secondValue: string | number
  handleSecondValueChange: ChangeEventHandler<HTMLInputElement>
  placeholders?: string[]
}

const DoubleInput = (props: Props) => {
  const {
    firstValue,
    secondValue,
    handleFirstValueChange,
    handleSecondValueChange,
    placeholders
  } = props;
  return (
    <div
      className='double-input-wrapper'
    >
      <span className="label-inside label-inside__double-plays">
        <span>wins</span>
        <input
          type="number"
          name="firstValue"
          value={firstValue}
          onChange={handleFirstValueChange}
          aria-label="firstValue"
          min={1}
          max={10000}
          className="double-input"
          placeholder={placeholders?.[0] ? placeholders[0] : ''}
        />
      </span>
      <span className="devider">/</span>
      <span className="label-inside label-inside__double-plays">
        <input
          type="number"
          name="secondValue"
          value={secondValue}
          onChange={handleSecondValueChange}
          aria-label="secondValue"
          min={1}
          max={10000}
          className="double-input"
          placeholder={placeholders?.[0] ? placeholders[0] : ''}
        />
        <span>plays</span>
      </span>
      <p>{getPercentage(Number(firstValue), Number(secondValue)) + '%'}</p>
    </div>
  );
};

export default DoubleInput;
