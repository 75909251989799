import { type Pool } from "./Pool";

export interface Prize {
  id: number | null;
  internalPrizeId: string;
  updatedAt: string;
  createdAt: string;
  title: string;
  description: string;
  gameImage: string;
  gameImageFilename: string | null;
  resultsImage: string | null;
  resultsImageFilename: string | null;
  legalNotes: string;
  labels: string[];
  active: number;
  amount: number;
  available: number;
  daysValid: number;
  reserved: number;
  preAllocated: number;
  redeemed: number;
  pools: Pool[];
  companyId: string;
  codeType?: string;
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class PrizeFactory {
  static getInitialData(companyId: string | undefined): Prize {
    return {
      id: null,
      internalPrizeId: "",
      updatedAt: "",
      createdAt: "",
      title: "",
      description: "",
      gameImage: "",
      gameImageFilename: "",
      resultsImage: "",
      resultsImageFilename: "",
      legalNotes: "",
      labels: [""],
      active: 0,
      amount: 0,
      available: 0,
      daysValid: 6,
      reserved: 0,
      preAllocated: 0,
      redeemed: 0,
      pools: [],
      companyId: companyId ?? "",
    };
  }
}
