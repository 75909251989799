import React, { type ChangeEventHandler, Component } from "react";
import { ReactComponent as PngIcon } from "../../../assets/icons/png-image.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload-small.svg";
import "./UploadImage.scss";

interface Props {
  text: string;
  height?: number;
  width?: number;
  preview: string | null;
  name: string;
  title?: string;
  onChange: (
    e: ChangeEventHandler<HTMLInputElement> | null,
    name?: string | null,
  ) => void;
}

interface State {
  file: File | null;
  filename: string | null;
  preview: string;
}

class UploadImage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      file: null,
      filename: null,
      preview: this.props.preview ? this.props.preview : "",
    };

    this.onFileChange = this.onFileChange.bind(this);
  }

  onFileChange(e: any) {
    if (
      !["image/png", "image/gif", "image/jpeg"].includes(e.target.files[0].type)
    ) {
      return;
    }
    const url = URL.createObjectURL(e.target.files[0]);

    this.setState({
      file: e.target.files[0],
      filename: e.target.files[0].name,
      preview: url,
    });
    this.props.onChange(e);
  }

  clearImage(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    e.preventDefault();
    this.setState({
      file: null,
      preview: "",
    });
    this.props.onChange(null, this.props.name);
  }

  render() {
    return (
      <React.Fragment>
        <div className="image-container">
          {this.state.preview ? (
            <div className="upload" title={this.props.text}>
              <img src={this.state.preview} alt={this.props.text} />
            </div>
          ) : (
            <div className="upload" title={this.props.text}>
              <img
                src={require("../../../assets/images/no-image.png")}
                alt="no image"
              />
            </div>
          )}
          <div className="prizes-file-upload">
            <p className="title">{this.props.text}</p>
            {this.state.preview ? (
              <label className="file selected">
                <PngIcon className="csv_icon" />
                <p>
                  {this.state.file ? this.state.file.name : this.props.title}
                </p>
                <CloseIcon
                  className="close_icon"
                  onClick={(e) => {
                    this.clearImage(e);
                  }}
                />
              </label>
            ) : (
              <label>
                <input
                  name={this.props.name}
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange}
                  className="upload-btn"
                  type="file"
                />
                <UploadIcon />
                {"Upload"}
              </label>
            )}
            <p className="size">
              File format: PNG, JPG, JPEG Maximum file size: 3MB.
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UploadImage;
