import React, { useEffect, useState } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import TableCell from "../../../shared/Table/TableCell";
import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";
import Loader from "../../../shared/Loader/Loader";
import Table from "../../../shared/Table/Table";
import TableHead from "../../../shared/Table/TableHead";
import TableHeadCell from "../../../shared/Table/TableHeadCell";
import TableBody from "../../../shared/Table/TableBody";
import TableRow from "../../../shared/Table/TableRow";
import { type Winner } from "../../../../interfaces/Winner";
import Pagination from "../../../shared/Pagination/Pagination";
import {
  truncateText,
} from "../../../../util/util";
import Button from "../../../shared/Button/Button";
import { useAppDispatch, useAppSelector } from "../../../../data/hooks";
import { resendPrize } from "../../../../data/slices/resendPrizeSlice";
import Alert from "../../../shared/Alert/Alert";
import "./WinnerSearchTable.scss";
import Modal from "../../../shared/Modal/Modal";
import Dropdown from "../../../shared/Dropdown/Dropdown";
import TextArea from "../../../shared/TextArea/TextArea";
import { type Prize } from "../../../../interfaces/Prize";
import { type Option } from "../../../../interfaces/Option";
import Confirm from "../../../shared/Confirm/Confirm";
import { modal } from "../../../../data/slices/notificationSlice";
import {useColumns} from "../useColumns";

interface Props {
  data: Winner[];
  isLoading: boolean;
  totalCount: number;
  onPageChange: (page: string | number) => void;
  currentPage: number;
  campaignId: string | undefined;
  generateWin: (
    email: string | undefined,
    prizeId: string | number,
    visitorId: string | null,
    reason: string,
    language: string,
    languageUUID: string,
  ) => void;
  campaignTimeZoneOffset: number;
}

const WinnerSearchTable: React.FC<Props> = (props: Props) => {
  const {
    data,
    isLoading,
    totalCount,
    onPageChange,
    currentPage,
    campaignId,
    generateWin,
    campaignTimeZoneOffset,
  } = props;
  const dispatch = useAppDispatch();
  const [prizeOptions, updatePrizeOptions] = useState([]);
  const [reason, updateReason] = useState("");
  const [selectedPrize, updateSelectedPrize] = useState<Option | null>(null);
  const [visitorId, updateVisitorId] = useState<string | null>(null);
  const [resetSuccessMessage, setResetSuccessMessage] = useState(
    "Successfully sent email.",
  );
  const isResending = useAppSelector(
    (state: any) => state.resendPrize.status === "loading",
  );
  const prizes = useAppSelector((state: any) => state.winnerPrizes.data);
  const isLoadingPrizes = useAppSelector(
    (state: any) => state.winnerPrizes.status === "loading",
  );

  const {
    columns,
    codeName,
    isCodeAlertOpen,
    winner,
    isEmailConfirmOpen,
    isModalOpen,
    setIsModalOpen,
    setIsEmailConfirmOpen,
    setIsCodeAlertOpen
  } = useColumns(campaignTimeZoneOffset, campaignId, isResending);

  useEffect(() => {
    getVisitorId();
  }, []);

  useEffect(() => {
    const options = prizes
      .filter((prize: Prize) => !!prize.available)
      .map((prize: Prize) => {
        return {
          id: prize.id,
          label: `${truncateText(prize.title, 20)} (Available ${
            prize.available
          })`,
          title: prize.title,
        };
      });

    updateSelectedPrize(options[0]);
    updatePrizeOptions(options);
  }, [prizes]);

  const getVisitorId = () => {
    const fpPromise = FingerprintJS.load().then();

    void (async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise;
      const result = await fp.get();

      // This is the visitor identifier:
      updateVisitorId(result.visitorId);
    })();
  };

  const generateNewWin = () => {
    generateWin(
      winner?.email,
      getSelectedPrize()?.id,
      visitorId,
      reason,
      winner?.language ?? "",
      winner?.languageUUID ?? "",
    );

    setIsModalOpen(false);
    updateReason("");
  };

  const sendEmail = () => {
    if (winner?.uuid) {
      dispatch(
        resendPrize({
          uuid: winner.uuid,
          campaignId: campaignId ?? "",
        }),
      ).then(() => {
        setResetSuccessMessage(
          winner.emailsDisabled
            ? "Successfully reset prize."
            : "Successfully sent email.",
        );
        setIsEmailConfirmOpen(false);
        dispatch(
          modal.notify({
            message: resetSuccessMessage,
            type: "success",
          }),
        );
      });
    }
  };



  const renderTableCell = (row: any, column: any, index: number) => {
    return (
      <TableCell key={index}>
        {column.render ? (
          column.render(row, column.value)
        ) : (
          <span className="elipsis-275" title={row[column.value]}>
            {row[column.value]}
          </span>
        )}
      </TableCell>
    );
  };



  const handlePrizeChange = (option: Option) => {
    updateSelectedPrize(option);
  };

  const getSelectedPrize = () => {
    return selectedPrize ?? prizeOptions[0];
  };

  const handleReasonChange = (e: any) => {
    updateReason(e.target.value);
  };

  return (
    <ErrorBoundary>
      <Loader isLoading={isLoading}>
        <Table className="winner-search-table">
          <TableHead>
            {columns.map(({ label }, index) => (
              <TableHeadCell key={index} heading={label} />
            ))}
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                {columns.map((column, i) => renderTableCell(row, column, i))}
              </TableRow>
            ))}
          </TableBody>
          <Pagination
            colSpan={columns.length}
            totalCount={totalCount}
            showPerPage={8}
            currentPage={currentPage}
            onPageChange={onPageChange}
          />
        </Table>
      </Loader>

      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="Generate new win"
        customClass="new-win-modal"
      >
        <Loader isLoading={isLoadingPrizes} height={300}>
          {prizeOptions.length ? (
            <>
              <div className="new-win-modal__input">
                <span className="new-win-modal__label">Player email:</span>
                <p>{winner?.email}</p>
              </div>
              <div className="new-win-modal__input">
                <span className="new-win-modal__label">Select prize</span>
                <Dropdown
                  data={prizeOptions}
                  selectedValue={getSelectedPrize()}
                  handleChange={handlePrizeChange}
                />
              </div>
              <div className="new-win-modal__input">
                <span className="new-win-modal__label">Reason</span>
                <TextArea
                  placeholder="Please describe the reason why new prize was issued"
                  name="reason"
                  onChange={handleReasonChange}
                  value={reason}
                  customClass={"in-winners"}
                />
              </div>
              <div className="modal__footer">
                <div className="modal__footer--btns">
                  <Button
                    type="secondary"
                    customClass="btn-small"
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    customClass="btn-small"
                    onClick={() => {
                      generateNewWin();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="modal__no-prizes-available">
                No prizes available.
              </div>
              <div className="modal__footer">
                <div className="modal__footer--btns">
                  <Button
                    type="secondary"
                    customClass="btn-small"
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </>
          )}
        </Loader>
      </Modal>

      <Alert
        isOpen={isCodeAlertOpen}
        setIsOpen={setIsCodeAlertOpen}
        title="Code"
        text={codeName}
        type="primary"
      />

      <Confirm
        isOpen={isEmailConfirmOpen}
        setIsOpen={setIsEmailConfirmOpen}
        title="Resend prize?"
        text=""
        buttonText="Yes"
        type="primary"
        action={() => sendEmail()}
        isLoading={isResending}
      />
    </ErrorBoundary>
  );
};

export default WinnerSearchTable;
