import { type Language } from "./Language";

export interface Campaign {
  builderVersion: string | null;
  campaignType: CampaignType;
  collectAnalytics: boolean;
  companyId: string;
  companyName: string;
  createdDate: string;
  demoUrl: string;
  description: string;
  doubleOptIn: boolean;
  endDate: string | null;
  id: string;
  isTailorMade: boolean;
  languages: Language[];
  leadExportUrl: string | null;
  leadTableUrl: string | null;
  liveUrl: string | null;
  logoUrl: string;
  multiRegistration: boolean;
  name: string;
  numberOfLeads: null;
  numberOfPlaysPerDay: null;
  preciseGeolocation: boolean;
  productionUrl: string;
  resetAnalyticsUrl: string | null;
  startDate: string | null;
  status: string;
  tailorMadeTableUrl: string | null;
}

export enum CampaignType {
  MEMORY = "memory-game",
  QUIZ = "quiz",
  DOPE_OR_NOPE = "dope-or-nope",
  GUESS_THE_PICTURE = "guess-the-picture",
  PERSONALITY_TEST = "personality-test",
  ADVENT_CALENDAR = "advent-calendar",
  MONTHLY_CALENDAR = "monthly-calendar",
  EASTER_CALENDAR = "easter-calendar",
  SPIN_THE_WHEEL = "spin-the-wheel",
  ADVANCED_SPIN_THE_WHEEL = "advanced-spin-the-wheel",
  SURVEY = "survey",
  WEEKLY_CALENDAR = "weekly-calendar",
  SCRATCH_CARD = "scratch-card",
  PENNY_SPIN_THE_WHEEL = "penny-spin-the-wheel",
  PENNY_PATH = "penny-path",
  PENNY_SLOT_MACHINE = "penny-slot-machine",
  BIPA_SPIN_THE_WHEEL = "bipa-spin-the-wheel",
}
