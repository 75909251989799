import { api } from './api';

export const getCodes = async (batchId: number, searchTerm: string) => {
  const params = {
    searchTerm
  };
  return await api.get(`prize-management/codes/${batchId}`, { params })
    .then((result) => result);
};

export const getCodeById = async (codeId: number) => {
  return await api.get(`prize-management/code/${codeId}`)
    .then((result) => result);
};
