import { createSlice, type Dispatch } from "@reduxjs/toolkit";
import { type RootState } from "../store";
import { type Campaign } from "../../interfaces/Campaign";
import { getCampaign } from "../api/campaigns";
import {
  STATUS_FAILED,
  STATUS_IDLE,
  STATUS_LOADING,
} from "../../constants/constants";

export interface CampaignState {
  data: Campaign | null;
  status: typeof STATUS_IDLE | typeof STATUS_FAILED | typeof STATUS_LOADING;
}

const initialState: CampaignState = {
  data: null,
  status: STATUS_IDLE,
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    getCampaignStarted: (state) => {
      state.status = STATUS_LOADING;
    },
    getCampaignSuccess: (state, action) => {
      state.status = STATUS_IDLE;
      if (action.payload.data) {
        state.data = action.payload.data;
      } else {
        state.data = initialState.data;
      }
    },
    getCampaignError: (state, action) => {
      state.status = STATUS_FAILED;
      console.log(action.payload.code, action.payload.message);
    },
    getEmptyCampaign: (state, action) => {
      state.status = STATUS_IDLE;
      state.data = initialState.data;
    },
  },
});

const {
  getCampaignStarted,
  getCampaignSuccess,
  getCampaignError,
  getEmptyCampaign,
} = campaignSlice.actions;

export const selectCampaign = (state: RootState) => state.campaign.data;
export const selectStatus = (state: RootState) => state.campaign.status;

export default campaignSlice.reducer;

export const fetchCampaign = (campaignId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getCampaignStarted());
      const result = await getCampaign(campaignId);
      dispatch(getCampaignSuccess(result.data));
    } catch (error) {
      dispatch(getCampaignError(error));
    }
  };
};

export const emptyCampaign = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmptyCampaign([]));
  };
};
