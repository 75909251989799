import React from 'react';

import { ReactComponent as PagePrevious } from '../../../assets/icons/page-previous.svg';
import { ReactComponent as PageNext } from '../../../assets/icons/page-next.svg';
import './Paginations.scss';
import { DOTS, usePagination } from '../../../custom-hooks/usePagination';

interface Props {
  colSpan: number
  totalCount: number
  showPerPage: number
  currentPage: number
  onPageChange: (page: string | number) => void
}

const Pagination = ({
  colSpan,
  totalCount,
  showPerPage,
  currentPage,
  onPageChange
}: Props) => {
  const siblingCount = 1;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    showPerPage
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange?.[paginationRange.length - 1];
  const firstPage = paginationRange?.[0];

  return (
    <tfoot className="pagination">
      <tr>
        <td colSpan={colSpan}>
          <div className="pagination__controls">
            <div className="pagination__controls--left">
              <span>
                {showPerPage}/{totalCount}
              </span>{' '}
              items
            </div>

            <div className="pagination__controls--right">
              <ul>
                {currentPage !== firstPage
                  ? (
                  <li>
                    <button
                      className={`link ${
                        currentPage === firstPage ? 'disabled' : ''
                      }`}
                    >
                      <PagePrevious
                        onClick={() => {
                          onPrevious();
                        }}
                      />
                    </button>
                  </li>
                    )
                  : (
                      ''
                    )}
                {paginationRange?.map((pageNumber, index) => {
                  if (pageNumber === DOTS) {
                    return (
                      <li key={index}>
                        <button className="dots">
                          <li key={index}>&#8230;</li>
                        </button>
                      </li>
                    );
                  }

                  return (
                    <li key={index}>
                      <button
                        className={`number ${
                          pageNumber === currentPage ? 'active' : ''
                        }`}
                        onClick={() => onPageChange(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    </li>
                  );
                })}
                {currentPage !== lastPage
                  ? (
                  <li>
                    <button
                      className={`link ${
                        currentPage === lastPage ? 'disabled' : ''
                      }`}
                      onClick={() => {
                        onNext();
                      }}
                    >
                      <PageNext />
                    </button>
                  </li>
                    )
                  : (
                      ''
                    )}
              </ul>
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  );
};

export default Pagination;
