import type {Column} from "../../../interfaces/Column";
import {dateTimeInPast, formatISODate, formatPrizeExpirationDate} from "../../../util/util";
import ActionsDropdown from "../../shared/ActionsDropdown/ActionsDropdown";
import React, {useState} from "react";
import {NO_WIN, POOL_ACTIVE, POOL_DEFAULT} from "../../../constants/constants";
import type {Winner} from "../../../interfaces/Winner";
import {useAppDispatch} from "../../../data/hooks";
import {fetchWinnerPrizes} from "../../../data/slices/winnerPrizes";
import {ReactComponent as Eye} from "../../../assets/icons/eye.svg";


export const useColumns = (campaignTimeZoneOffset: number, campaignId: string | undefined, isResending: boolean) => {
  const [codeName, setCodeName] = useState("");
  const [isCodeAlertOpen, setIsCodeAlertOpen] = useState(false);
  const [winner, updateWinner] = useState<Winner | null>(null);
  const [isEmailConfirmOpen, setIsEmailConfirmOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  const setCode = (code: string) => {
    setCodeName(code);
    setIsCodeAlertOpen(true);
  };

  const checkIfExpired = (data: any, columnValue: string) => {
    if (data[columnValue]) {
      const isInPast = dateTimeInPast(data[columnValue]);
      return (
        <span className={isInPast ? "red" : ""}>
          {formatPrizeExpirationDate(data[columnValue], campaignTimeZoneOffset)}
          {isInPast && <br/>}
          {isInPast && "EXPIRED"}
        </span>
      );
    }
    return "";
  };

  const getDropdownOptions = (data: any) => {
    const isPrizeExpired = dateTimeInPast(data.prize_expiration_date);
    const isResendCodeDisabled =
      ![POOL_ACTIVE, POOL_DEFAULT].includes(data.poolStatus) ||
      data.prizeTitle === NO_WIN ||
      (isPrizeExpired && !data.dateRedeemed) ||
      isResending;

    return [
      {
        id: "newWin",
        label: "Generate new win",
        disabled: data.prizeTitle === NO_WIN,
      },
      {
        id: "resendCode",
        label: "Resend Prize",
        disabled: isResendCodeDisabled,
      },
    ];
  };

  const openModal = () => {
    setIsModalOpen(true);
    if (campaignId) {
      dispatch(fetchWinnerPrizes(campaignId));
    }
  };

  const handleDropdownChange = (option: any, data: any) => {
    updateWinner(data);
    if (option.id === "newWin") {
      openModal();
    }
    if (option.id === "resendCode") {
      setIsEmailConfirmOpen(true);
    }
  };

  const columns: Column[] = [
    {
      label: "Player email",
      value: "email",
    },
    {
      label: "Prize name",
      value: "prizeTitle",
      render: (data: any, columnValue: string) => (
        <span className="elipsis-275" title={data[columnValue]}>
          {data[columnValue]}
        </span>
      ),
    },
    {
      label: "Created",
      value: "playRegistrationDate",
      render: (data: any, columnValue: string) =>
        formatISODate(data[columnValue], campaignTimeZoneOffset),
    },
    {
      label: "Prize expiration date",
      value: "prize_expiration_date",
      render: (data: any, columnValue: string) =>
        checkIfExpired(data, columnValue),
    },
    {
      label: "Redeemed",
      value: "isRedeemed",
      render: (data: any, columnValue: string) =>
        data[columnValue] ? "Yes" : "No",
    },
    {
      label: "Redeemed date and time",
      value: "dateRedeemed",
      render: (data: any, columnValue: string) => {
        if (data[columnValue]) {
          return formatISODate(data[columnValue], campaignTimeZoneOffset);
        }
        return "-";
      },
    },
    {
      label: "Prize code",
      value: "code",
      render: (data: any, columnValue: string) => {
        if (data[columnValue]) {
          return (
            <div
              className="prize-code cursor-pointer"
              title="See code"
              onClick={() => setCode(data[columnValue])}
            >
              <Eye/>
              <span>Code</span>
            </div>
          );
        }
        return "";
      },
    },
    {
      label: "Actions",
      value: "",
      render: (data) => {
        return (
          <ActionsDropdown
            data={getDropdownOptions(data)}
            handleChange={(option: any) => handleDropdownChange(option, data)}
          />
        );
      },
    },
  ];

  return {
    columns,
    codeName,
    isCodeAlertOpen,
    winner,
    isEmailConfirmOpen,
    isModalOpen,
    setIsModalOpen,
    setIsEmailConfirmOpen,
    setIsCodeAlertOpen
  };
};