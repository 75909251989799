import { api } from './api';
import { type SearchPools } from '../../interfaces/SearchPools';
import { type Pool } from '../../interfaces/Pool';

export const searchPools = async (data: SearchPools) => {
  return await api.post('prize-management/pools', data)
    .then((result) => result);
};

export const getPools = async (campaignId: string) => {
  return await api.get(`prize-management/campaigns/${campaignId}/pools`)
    .then((result) => result);
};

export const getPoolsByCompany = async (companyId: string) => {
  return await api.get(`prize-management/companies/${companyId}/pools`)
    .then((result) => result);
};

export const createPool = async (data: Pool) => {
  return await api.post('prize-management/pools/create', data)
    .then((result) => result);
};

export const getPool = async (id: number) => {
  return await api.get(`prize-management/pools/${id}`)
    .then((result) => result);
};

export const putPool = async (data: Pool) => {
  return await api.put(`prize-management/pool/${data.id}`, data)
    .then((result) => result);
};

export const deletePool = async (id: number) => {
  return await api.delete(`prize-management/pools/delete/${id}`)
    .then((result) => result);
};

export const copyPool = async (id: number, payload: { copyCodes?: boolean }) => {
  return await api.post<{ data: Pool }>(`prize-management/pools/${id}/copies`, payload)
    .then(({ data }) => data.data);
};
