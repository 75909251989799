import React, { useEffect, useState } from "react";

import "./InCampaign.scss";
import { type Pool } from "../../../../interfaces/Pool";
import SearchAutoComplete from "../../../shared/SearchAutoComplete/SearchAutoComplete";
import { useAppDispatch, useAppSelector } from "../../../../data/hooks";
import {
  emptyCampaigns,
  fetchCampaigns,
} from "../../../../data/slices/campaignsSlice";
import {
  emptyCampaign,
  fetchCampaign,
} from "../../../../data/slices/campaignSlice";
import { type SearchCampaigns } from "../../../../interfaces/SearchCampaigns";
import { type Campaign } from "../../../../interfaces/Campaign";
import { type Option } from "../../../../interfaces/Option";
import Button from "../../../shared/Button/Button";
import { ReactComponent as Plus } from "../../../../assets/icons/plus-green.svg";
import { ReactComponent as Delete } from "../../../../assets/icons/delete.svg";
import {
  calculateCampaignEndDate,
  formatCampaignType,
  getDate,
} from "../../../../util/util";
import EmptyBox from "../../../shared/EmptyBox/EmptyBox";
import { useAuth } from "../../../../custom-hooks/useAuth";

interface Props {
  pool: Pool;
  handleDataChange: (pool: Pool) => void;
}

const campaignOption = {
  id: "",
  label: "",
};

const InCampaign = ({ pool, handleDataChange }: Props) => {
  const {
    user: { companyId },
  } = useAuth();
  const initialRequestData: SearchCampaigns = {
    page: 0,
    size: 40,
    companyId: companyId ?? "",
    orderBy: "createdDate",
    direction: "DESC",
    name: "",
  };

  const campaigns = useAppSelector((state: any) => state.campaigns.data);
  const campaign = useAppSelector((state: any) => state.campaign.data);
  const dispatch = useAppDispatch();
  const [requestData, updateRequestData] = useState(initialRequestData);
  const [selectedCampaign, setSelectedCampaign] = useState(campaignOption);
  const [isAddingAllowed, setIsAddingAllowed] = useState(false);

  useEffect(() => {
    if (requestData) {
      dispatch(fetchCampaigns(requestData));
    } else {
      dispatch(emptyCampaigns());
    }
    const hasCampaigns = pool && pool.campaignIds.length > 0;
    if (hasCampaigns) {
      dispatch(fetchCampaign(pool.campaignIds[0]));
    }
    setIsAddingAllowed(!hasCampaigns);
  }, [dispatch, pool, requestData]);

  const getParsedCampaigns = () => {
    return campaigns.map((campaign: Campaign) => {
      return {
        id: campaign.id,
        label: campaign.name,
      };
    });
  };

  const search = (e: any) => {
    updateRequestData({
      ...requestData,
      name: e.target.value,
    });
  };

  const handleCampaignChange = (id: string) => {
    const campaign = getParsedCampaigns().find(
      (campaign: Option) => campaign.id === id,
    );
    if (campaign) {
      setSelectedCampaign(campaign);
    }
  };

  const addPoolToCampaign = () => {
    if (selectedCampaign && !pool.campaignIds.includes(selectedCampaign.id)) {
      handleDataChange({
        ...pool,
        campaignIds: [...pool.campaignIds, selectedCampaign.id],
      });
      setSelectedCampaign(campaignOption);
      dispatch(emptyCampaigns());
    }
  };

  const removeCampaign = (campaignId: string) => {
    handleDataChange({
      ...pool,
      campaignIds: [],
    });
    dispatch(emptyCampaign());
  };

  return (
    <div className="in-campaign">
      <p className="info">
        Find a specific campaign to which you want to add your prize pool.
      </p>
      <div className="in-campaign__header">
        <SearchAutoComplete
          options={getParsedCampaigns()}
          selectedOption={selectedCampaign}
          handleSelectChange={handleCampaignChange}
          handleSearch={search}
          disabled={!isAddingAllowed}
          placeholder={"Search campaigns"}
        />

        <Button
          type="secondary"
          disabled={!isAddingAllowed || !selectedCampaign.id}
          onClick={addPoolToCampaign}
        >
          <Plus /> Add to campaign
        </Button>
      </div>
      {campaign ? (
        <div className="in-campaign__body">
          <div className="campaign-fields__summary">
            <p className="campaign-fields__name">Campaign name</p>
            <p className="campaign-fields__value">{campaign?.name}</p>
          </div>
          <div className="campaign-fields__summary">
            <p className="campaign-fields__name">Campaign type</p>
            <p className="campaign-fields__value">
              {formatCampaignType(campaign?.campaignType)}
            </p>
          </div>
          <div className="campaign-fields__summary">
            <p className="campaign-fields__name">Date created</p>
            <p className="campaign-fields__value">
              {campaign.createdDate
                ? getDate(campaign.createdDate.replace("T", " "))
                : "N/A"}
            </p>
          </div>
          <div className="campaign-fields__summary">
            <p className="campaign-fields__name">Start date</p>
            <p className="campaign-fields__value">
              {campaign.startDate
                ? getDate(campaign.startDate.replace("T", " "))
                : "N/A"}
            </p>
          </div>
          <div className="campaign-fields__summary last">
            <p className="campaign-fields__name">End date</p>
            <p className="campaign-fields__value">
              {campaign?.startDate && pool?.durationInDays
                ? calculateCampaignEndDate(
                    campaign.startDate,
                    pool.durationInDays,
                  )
                : "N/A"}
            </p>
          </div>

          <Button
            type="secondary"
            customClass="campaign-fields__remove"
            onClick={() => {
              removeCampaign(campaign?.id);
            }}
          >
            <Delete /> Remove from Campaign
          </Button>
        </div>
      ) : (
        <EmptyBox title={"No campaign added."} height={369} />
      )}
    </div>
  );
};

export default InCampaign;
